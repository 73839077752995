import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Datepicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';

import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//slider
import Slider from 'react-slick';
// Dropify
import Dropzone from 'react-dropzone';
// Switch Toggle Button
import Switch from 'react-switch';
// Upload Gambar
import Resizer from 'react-image-file-resizer';
import axios from 'axios';

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin, JENIS_DESA, Post_Marketplace } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Datetime from 'react-datetime';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: '',
	username: '',
	password: '',
	nik: 0,
	id_role: '',
	status: null,
	no_hp: null,
	email: null
};

const groupBy = key => array =>
	array.reduce(
		(objectsByKeyValue, obj) => ({
			...objectsByKeyValue,
			[obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
		}),
		{}
	);

var moment = require('moment');
const dateFormat = require('dateformat');

class DetailProfitBumdes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// data: [],
			dataPrepaid: [],
			dataPrepaidDigiflaz: [],
			listPrepaidSKU: {},
			listPrepaidBrand: [],
			SKUPrepaid: [],
			dataPostpaid: [],
			dataPostpaidDigiflaz: [],
			listPostpaidSKU: {},
			listPostpaidBrand: [],
			SKUPostpaid: [],
			dataRekapProfit: [],
			dataKonfig: null,
			pagination: [],
			status: {
				form: false,
				formBanner: false,
				formProvider: false,
				btnForm: false,
				btnAksi: false,
				formVerifikasi: false,
				statGet: false,
				formReset: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// =========== Select 2 ============

			active_tab_icon: '1',
			// Tab Vertical
			active_tab_vertical: '1',

			// Konfig
			konfig_postpaid: 'none',
			konfig_prepaid: 'none',
			detailProfit: null,
			detailProvider: null,
			detailProfitActive: false,
			detailPrepaid: {
				brand: '',
				product_name: '',
				buyer_sku_code: '',
				category: '',
				nama_server: '',
				nominal_keuntungan: '',
				nominal_penjualan: '',
				nominal_modal: '',
			},
			currentPrepaidIndex: null,
			detailPostpaid: {
				brand: '',
				product_name: '',
				buyer_sku_code: '',
				category: '',
				nama_server: '',
				nominal_keuntungan: '',
				nominal_penjualan: '',
				nominal_modal: '',
			},
			currentPostpaidIndex: null,
			// new used
			detailData: {
				brand: '',
				product_name: '',
				buyer_sku_code: '',
				category: '',
				nama_server: '',
				nominal_keuntungan: 0,
				nominal_penjualan: 0,
				nominal_modal: 0,
				nominal_het: 0,
			},
			currentDetailIndex: null,
			tanggal_mulai: null,
			tanggal_selesai: new Date,

			// Toggle BUtton
			checked: false,

			post_pulsa: '',
			post_pln: '',
			post_pdam: '',
			post_tv: '',

			pre_pulsa: '',
			pre_pln: '',
			pre_pdam: '',
			pre_tv: '',
			listPrepaid: [
				'Pulsa',
				'PLN',
				'Games',
			],
			listPostpaid: [
				'PLN PASCABAYAR',
				'BPJS KESEHATAN',
				'HP PASCABAYAR',
				'INTERNET PASCABAYAR',
				'MULTIFINANCE',
			],
			listBrand: [],
			detailListPrepaid: null,
			detailListPostpaid: null,

			rowInUpdate: null,

			// Data Banner
			data_banner: [],
			data_detail_banner: [],
			foto_desa: '',
			link_gambar: '',
			link_gambar_lama: '',
			link_gambar_show: null,
			get_link_gambar: null,
			data_cek: '',

			// Loading
			loading: true,
			loading_konfig: true,

			// Search Data
			url_cari: ''
		};

		this.handleChangeUmum = this.handleChangeUmum.bind(this);
	}

	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// Toggle Vertical Tab
	toggle_vertical(tab) {
		if (this.state.active_tab_vertical !== tab) {
			this.setState({
				active_tab_vertical: tab,
				active_tab_icon: '1',
			});
		}
	}

	// ================== DatePicker ==================
	gantiTglMulai = (date) => {
		this.state.dt.tanggal_mulai = date;
		this.forceUpdate();
	};
	gantiTglSelesai = (date) => {
		this.state.dt.tanggal_selesai = date;
		this.forceUpdate();
	};
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		let extension = '';

		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}

		this.setState({ loading: false })

		let uuid = this.props.data.uuid
		let dataRekapProfit = []

		if (params.extension) {
			extension = params.extension
		}

		Get('profit/bumdes/transaksi?uuid_bumdes=' + uuid + extension + link, null, (res) => {
			console.log(res);
			if (res.data.data.data) {
				dataRekapProfit = res.data.data.data
				this.setState({ 
					dataRekapProfit, 
					pagination: res.data.data
				})
				// this.pagination(res.data.data);
			} else {
				this.setState({ 
					dataRekapProfit: []
				})
			}
			this.pagination(res.data.data, extension);
		})
	};

	// Pagination
	pagination = (data, extension) => {
		// const data = this.state.pagination;
		console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i, extension })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, extension })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, extension })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1, extension })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page, extension })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// Cari Data Banner
	cariData = (e) => {
		if (e.key == 'Enter') {
			this.state.loading = true;
			// this.forceUpdate();
			// let addRole = {
			// 	q: e.target.value
			// };
			// Get('banner?keyword=' + e.target.value, null, (dtkat) => {
			// 	if(dtkat.data){
			// 		this.setState({
			// 			data_banner: dtkat.data.data,
			// 			loading: false,
			// 			pagination: dtkat.data
			// 		});
			// 	}
			// 	else{
			// 		this.setState({
			// 			data_banner: [],
			// 			loading: false,
			// 			// pagination: dtkat.data
			// 		});
			// 	}
			// });
			this.state.url_cari = 'keyword=' + e.target.value;
			this.forceUpdate();
			this.componentDidMount();
		}
	};

	// ========================================================================

	// Editable cell
	editableCellHandle = (oldData, dataField, newValue) => {
		let bodyRaw = {}
		if (this.state.dataKonfig) {
			bodyRaw = {
				uuid: this.state.dataKonfig.uuid,
				prepaid: this.state.dataKonfig.prepaid,
				postpaid: this.state.dataKonfig.postpaid,
				level_akun: 'bumdes',
				uuid_pengguna: this.props.data.uuid,
			}
		}

		let category = oldData.category
		let brand = oldData.brand
		let nama_server = oldData.nama_server
		let product_name = oldData.product_name
		let buyer_sku_code = oldData.buyer_sku_code
		let nominal_penjualan = parseInt(oldData.nominal_penjualan)
		let nominal_het = parseInt(oldData.nominal_het)
		let nominal_keuntungan = parseInt(oldData.nominal_keuntungan)

		switch (dataField) {
			case 'category':
				category = newValue
				break;
			case 'brand':
				brand = newValue
				break;
			case 'nama_server':
				nama_server = newValue
				break;
			case 'product_name':
				product_name = newValue
				break;
			case 'buyer_sku_code':
				buyer_sku_code = newValue
				break;
			case 'nominal_penjualan':
				nominal_penjualan = parseInt(newValue)
				break;
			case 'nominal_het':
				nominal_het = parseInt(newValue)
				break;
			case 'nominal_keuntungan':
				nominal_keuntungan = parseInt(newValue)
				break;
		}

		let isiData = null
		
		switch (this.state.active_tab_vertical) {
			case '1':
				// isiData = this.state.dataPrepaid
				isiData = JSON.parse(bodyRaw.prepaid)
				break;
			case '2':
				// isiData = this.state.dataPostpaid
				isiData = JSON.parse(bodyRaw.postpaid)
				break;
		}

		isiData[this.state.currentDetailIndex] = { ...isiData[this.state.currentDetailIndex],
			category,
			brand,
			// nama_server,
			product_name,
			buyer_sku_code,
			nominal_penjualan,
			nominal_keuntungan,
			nominal_het,
		}

		let psn = 'Memperbarui'

		if (this.state.dataKonfig) {
			switch (this.state.active_tab_vertical) {
				case '1':
					bodyRaw.prepaid = JSON.stringify(isiData)
					break;
				case '2':
					bodyRaw.postpaid = JSON.stringify(isiData)
					break;
			}
		}

		Post('bumdes/konfigurasi-profit', bodyRaw.uuid, bodyRaw, (res) => {
			if (res.status === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Konfigurasi Profit',
					pesanAlert: 'Berhasil ' + psn + ' Data Profit',
					currentDetailIndex: null
				});
				this.componentDidMount();
				// this.tutupForm();
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Konfigurasi Profit',
					pesanAlert: 'Gagal ' + psn + ' Data Profit',
					currentDetailIndex: null
				});
				// this.componentDidMount();
				// this.tutupForm();
			}
		});
	}

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		let bodyRaw = {
			uuid: null,
			prepaid: '',
			postpaid: '',
			level_akun: 'bumdes',
			uuid_pengguna: this.props.data.uuid,
		}
		let psn = ''

		if (this.state.dataKonfig) {
			bodyRaw = {
				uuid: this.state.dataKonfig.uuid,
				prepaid: this.state.dataKonfig.prepaid,
				postpaid: this.state.dataKonfig.postpaid,
				level_akun: 'bumdes',
				uuid_pengguna: this.props.data.uuid,
			}
		}
		
		// Jika proses edit
		if (this.state.currentDetailIndex != null && this.state.currentDetailIndex !== -1) {
			if (this.state.detailData && this.state.detailData.brand != '' && this.state.detailData.buyer_sku_code != ''){
				let product_name = document.getElementById('product_name').value
				let category = this.state.detailData.category
				let brand = this.state.detailData.brand
				// let nama_server = document.getElementById('nama_server').value
				let nama_server = this.state.detailData.nama_server

				// Jika edit postpaid, ambil nominal modal dari detail data
				let nominal_modal = this.state.detailData.nominal_modal
				
				// let buyer_sku_code = document.getElementById('buyer_sku_code').value
				let buyer_sku_code = this.state.detailData.buyer_sku_code
				// let nominal_penjualan = 0
				let nominal_penjualan = this.state.detailData.nominal_penjualan
				// let nominal_het = ''
				let nominal_het = this.state.detailData.nominal_het
				let nominal_keuntungan = parseInt(document.getElementById('nominal_keuntungan').value)
	
				// if (this.state.active_tab_vertical != '2') {
				if (this.state.active_tab_vertical == '1') {
					// nominal_penjualan = parseInt(document.getElementById('nominal_penjualan').value)
					nominal_modal = parseInt(document.getElementById('nominal_modal').value)
					nominal_het = parseInt(document.getElementById('nominal_het').value)
				}
	
				let isiData = {}
	
				switch (this.state.active_tab_vertical) {
					case '1':
						isiData = this.state.dataPrepaid
						break;
					case '2':
						isiData = this.state.dataPostpaid
						break;
				}
	
				isiData[this.state.currentDetailIndex] = { ...isiData[this.state.currentDetailIndex],
					category,
					brand,
					nominal_modal,
					product_name,
					buyer_sku_code,
					nominal_penjualan,
					nominal_keuntungan,
					nominal_het,
				}
	
				psn = 'Memperbarui'
	
				if (this.state.dataKonfig) {
					switch (this.state.active_tab_vertical) {
						case '1':
							bodyRaw.prepaid = JSON.stringify(isiData)
							break;
						case '2':
							bodyRaw.postpaid = JSON.stringify(isiData)
							break;
					}
				}
	
				Post('bumdes/konfigurasi-profit', bodyRaw.uuid, bodyRaw, (res) => {
					if (res.status === 200) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Berhasil ' + psn + ' Data Profit'
						});
						this.componentDidMount();
						this.tutupForm();
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Gagal ' + psn + ' Data Profit'
						});
						// this.componentDidMount();
						this.tutupForm();
					}
				});
			} else {
				console.log('Pilih brand terlebih dahulu');
				this.setState({
					show: true,
					basicType: "warning",
					basicTitle: "Data Belum Lengkap",
					pesanAlert: "Silahkan isi seluruh data yang diperlukan dengan benar"
				});
			}

		} else { // Jika proses tambah
			if (this.state.detailData && this.state.detailData.brand != '' && this.state.detailData.buyer_sku_code != '') {
				psn = 'Tambah'
				
				let category = this.state.detailData.category
				let brand = this.state.detailData.brand
				// let nama_server = document.getElementById('nama_server').value
				let nama_server = this.state.detailData.nama_server
				let nominal_modal = this.state.detailData.nominal_modal
				let product_name = document.getElementById('product_name').value
				// let buyer_sku_code = document.getElementById('buyer_sku_code').value
				let buyer_sku_code = this.state.detailData.buyer_sku_code
				let nominal_penjualan = 0
				let nominal_het = ''

				// if (this.state.active_tab_vertical != '2') {
				if (this.state.active_tab_vertical == '1') {
					// nominal_penjualan = parseInt(document.getElementById('nominal_penjualan').value)
					nominal_modal = parseInt(document.getElementById('nominal_modal').value)
					nominal_het = parseInt(document.getElementById('nominal_het').value)
				}
				
				let nominal_keuntungan = parseInt(document.getElementById('nominal_keuntungan').value)

				let isiData = {}

				switch (this.state.active_tab_vertical) {
					case '1':
						isiData = this.state.dataPrepaid
						break;
					case '2':
						isiData = this.state.dataPostpaid
						break;
				}

				console.log(isiData);

				isiData.push({
					category,
					brand,
					// nama_server,
					nominal_modal,
					product_name,
					buyer_sku_code,
					nominal_penjualan,
					nominal_keuntungan,
					nominal_het,
				})
				
				console.log(isiData);

				if (this.state.dataKonfig) {
					switch (this.state.active_tab_vertical) {
						case '1':
							bodyRaw.prepaid = JSON.stringify(isiData)
							break;
						case '2':
							bodyRaw.postpaid = JSON.stringify(isiData)
							break;
					}
					if (this.state.dataKonfig.uuid == '') {
						bodyRaw.uuid = null
					}
				}

				console.log(bodyRaw);

				Post('bumdes/konfigurasi-profit', bodyRaw.uuid, bodyRaw, (res) => {
					if (res.status === 200) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Berhasil ' + psn + ' Data Profit'
						});
						this.componentDidMount();
						this.tutupForm();
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data Konfigurasi Profit',
							pesanAlert: 'Gagal ' + psn + ' Data Profit'
						});
						// this.componentDidMount();
						this.tutupForm();
					}
				});

			} else {
				console.log('Pilih brand terlebih dahulu');
				this.setState({
					show: true,
					basicType: "warning",
					basicTitle: "Data Belum Lengkap",
					pesanAlert: "Silahkan isi seluruh data yang diperlukan dengan benar"
				});
			}
		}

	};
	// ========================================================================


	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (data) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(data)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (data) => {
		this.hideAlert();
		let psn = 'Hapus';
		if (this.state.dataKonfig) {
			let bodyRaw = {
				uuid: this.state.dataKonfig.uuid,
				prepaid: this.state.dataKonfig.prepaid,
				postpaid: this.state.dataKonfig.postpaid,
				level_akun: 'bumdes',
			};

			let currentDetail = []

			if (this.state.active_tab_vertical == '1') {
				currentDetail = this.state.dataPrepaid
			} else {
				currentDetail = this.state.dataPostpaid
			}

			currentDetail = currentDetail.filter(item => item !== data)

			// Update detaillistprepaid/detaillistpostpaid
			
			// if (this.state.active_tab_vertical == '1') {
			// 	currentDetail = this.state.dataPrepaid
			// 	this.setState({ dataPrepaid: currentDetail })
			// } else {
			// 	currentDetail = this.state.dataPostpaid
			// 	this.setState({ dataPostpaid: currentDetail })
			// }

			if (this.state.active_tab_vertical == '1') {
				bodyRaw.prepaid = JSON.stringify(currentDetail)
			} else {
				bodyRaw.postpaid = JSON.stringify(currentDetail)
			}


			Post('bumdes/konfigurasi-profit', bodyRaw.uuid, bodyRaw, (res) => {
				psn = 'Hapus'
				if (res.status === 200) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Konfigurasi Profit',
						pesanAlert: 'Berhasil ' + psn + ' Data Profit'
					});
					this.componentDidMount();
					this.tutupForm();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Konfigurasi Profit',
						pesanAlert: 'Gagal ' + psn + ' Data Profit'
					});
					// this.componentDidMount();
					this.tutupForm();
				}
			});
		}
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.dt.uuid = null;
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;

		this.state.status.form = false;
		this.state.status.formReset = false;
		this.state.status.formVerifikasi = false;
		this.state.status.formBanner = false;
		this.state.status.formProvider = false;
		this.state.link_gambar = '';
		this.state.data_detail_banner = [];
		this.state.detailProfit = null;
		this.state.detailProvider = null;
		this.state.currentPrepaidIndex = null;
		this.state.currentDetailIndex = null;

		this.state.detailPrepaid = {
			brand: '',
			product_name: '',
			buyer_sku_code: '',
			category: '',
			nama_server: '',
			nominal_keuntungan: '',
			nominal_penjualan: '',
			nominal_modal: '',
		}
		this.state.detailPostpaid = {
			brand: '',
			product_name: '',
			buyer_sku_code: '',
			category: '',
			nama_server: '',
			nominal_keuntungan: '',
			nominal_penjualan: '',
			nominal_modal: '',
		}
		this.state.detailData = {
			brand: '',
			product_name: '',
			buyer_sku_code: '',
			category: '',
			nama_server: '',
			nominal_keuntungan: '',
			nominal_penjualan: '',
			nominal_modal: '',
		}
		
		this.forceUpdate();
	};

	tombolAksi = (cell, row, type = '') => {
		// console.log(row);
		return (
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => {
						let currentDetailIndex = ''

						if (this.state.active_tab_vertical == '1') {
							currentDetailIndex = this.state.dataPrepaid.indexOf(row)
						} else {
							currentDetailIndex = this.state.dataPostpaid.indexOf(row)
						}
						
						this.setState({
							judul: 'Ubah Profit',
							statUbah: false,
							// detailPrepaid: row,
							detailData: row,
							// currentPrepaidIndex,
							currentDetailIndex,
						});
						this.bukaForm();
					}}
					// disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>
				{'    '}
				<Button
					size="xs"
					color="danger"
					className="btn-icon"
					onClick={() => {
						let currentDetailIndex = ''

						if (this.state.active_tab_vertical == '1') {
							currentDetailIndex = this.state.dataPrepaid.indexOf(row)
						} else {
							currentDetailIndex = this.state.dataPostpaid.indexOf(row)
						}

						// let currentDetailIndex = this.state.data.indexOf(row)

						this.setState({
							// detailPrepaid: row,
							// currentPrepaidIndex
							detailData: row,
							currentDetailIndex
						});
						this.konfirmHapus(row)
					}}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-trash" />
				</Button>
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
				case 'brand':
					this.setState({ detailData: {...this.state.detailData, brand: e.value} });
					break;
				case 'nama_server':
					this.setState({ detailData: {...this.state.detailData, nama_server: e.value} });
					break;
				case 'buyer_sku_code':
					this.setState({ detailData: {...this.state.detailData, buyer_sku_code: e.value} });
					break;
			}
		} else {
			switch (sel) {
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: null,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
				case 'brand':
					this.setState({ detailData: {...this.state.detailData, brand: ''} });
					break;
				case 'nama_server':
					this.setState({ detailData: {...this.state.detailData, nama_server: ''} });
					break;
				case 'buyer_sku_code':
					this.setState({ detailData: {...this.state.detailData, buyer_sku_code: ''} });
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	filterData(type, moment) {
		let extension = ''
		let tgl_mulai = ''
		let tgl_selesai = ''

		// Jika set tanggal mulai
		if (type == 'mulai') {
			let tanggal_mulai = new Date(moment)
			tgl_mulai = dateFormat(tanggal_mulai, 'yyyy-mm-dd')

			if (this.state.tanggal_selesai) {
				tgl_selesai = dateFormat(this.state.tanggal_selesai, 'yyyy-mm-dd')
			}

			this.setState({ tanggal_mulai })
		} else { // Jika set tanggal selesai
			let tanggal_selesai = new Date(moment)

			if (this.state.tanggal_mulai) {
				tgl_mulai = dateFormat(this.state.tanggal_mulai, 'yyyy-mm-dd')
			}

			tgl_selesai = dateFormat(tanggal_selesai, 'yyyy-mm-dd')
			this.setState({ tanggal_selesai })
		}

		// if (this.state.tanggal_selesai) {
		// }
		// if (this.state.tanggal_selesai) {
		// 	tanggal_selesai = dateFormat(this.state.tanggal_selesai, 'yyyy-mm-dd')
		// }

		extension = '&date_start=' + tgl_mulai + ' 00:00:00' + '&date_end=' + tgl_selesai + ' 23:59:59'

		this.fetch({ 
			extension
		});

	}

	groupingArray = (key) => (array) => {
		array.reduce(
			(objectsByKeyValue, obj) => ({
				...objectsByKeyValue,
				[obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
			}),
			{}
		);
	}

	componentDidMount() {

		console.log(this.props.data);
		
		let uuid = this.props.data.uuid

		Get('layanan/prepaid', null, (dtkat) => {
			if (dtkat.data) {
				let dataPrepaidDigiflaz = dtkat.data

				const groupByCategory = groupBy('category');
				const groupBySKU = groupBy('buyer_sku_code');
				const groupByBrand = groupBy('brand');

				let categorized = groupByCategory(dataPrepaidDigiflaz);
				let SKUed = groupBySKU(dataPrepaidDigiflaz);

				let categorizedSKU = {}
				let categorizedBrand = {}
				Object.keys(categorized).map((attribute, index) => {
					categorizedSKU[attribute] = groupBySKU(categorized[attribute])
					categorizedBrand[attribute] = groupByBrand(categorized[attribute])
				})

				this.setState({ SKUPrepaid: SKUed })

				// Set list sku prepaid
				let listPrepaidSKU = this.state.listPrepaidSKU
				Object.keys(categorizedSKU).map((category) => {
					listPrepaidSKU[category] = []
					Object.keys(categorizedSKU[category]).map((sku) => {
						listPrepaidSKU[category].push({
							value: sku,
							label: sku,
						})
					})
				})
				// console.log(listPrepaidSKU);

				// Set list brand prepaid
				let listPrepaidBrand = this.state.listPrepaidBrand
				Object.keys(categorizedBrand).map((category) => {
					listPrepaidBrand[category] = []
					Object.keys(categorizedBrand[category]).map((brand) => {
						listPrepaidBrand[category].push({
							value: brand,
							label: brand,
						})
					})
				})
				// console.log(listPrepaidSKU);

				this.setState({ 
					listPrepaidSKU, 
					listPrepaidBrand 
				})
			}
		})

		Get('layanan/postpaid', null, (dtkat) => {
			console.log(dtkat);
			if (dtkat.data) {
				let dataPostpaidDigiflaz = dtkat.data

				const groupByCategory = groupBy('category');
				const groupBySKU = groupBy('buyer_sku_code');
				const groupByBrand = groupBy('brand');

				let categorized = groupByCategory(dataPostpaidDigiflaz);
				let branded = groupByBrand(dataPostpaidDigiflaz);
				let SKUed = groupBySKU(dataPostpaidDigiflaz);
				// console.log(branded);

				let brandedSKU = {}
				let categorizedBrand = {}
				Object.keys(categorized).map((attribute, index) => {
					categorizedBrand[attribute] = groupByBrand(categorized[attribute])
				})
				Object.keys(branded).map((attribute, index) => {
					brandedSKU[attribute] = groupBySKU(branded[attribute])
				})
				// console.log(brandedSKU);

				this.setState({ SKUPostpaid: SKUed })

				// Set list sku postpaid
				let listPostpaidSKU = this.state.listPostpaidSKU
				Object.keys(brandedSKU).map((brand) => {
					listPostpaidSKU[brand] = []
					Object.keys(brandedSKU[brand]).map((sku) => {
						listPostpaidSKU[brand].push({
							value: sku,
							label: sku,
						})
					})
				})
				// console.log(listPostpaidSKU);

				// Set list brand postpaid
				let listPostpaidBrand = this.state.listPostpaidBrand
				Object.keys(categorizedBrand).map((category) => {
					// listPostpaidBrand[category] = []
					Object.keys(categorizedBrand[category]).map((brand) => {
						listPostpaidBrand[brand] = []
						listPostpaidBrand[brand].push({
							value: brand,
							label: brand,
						})
					})
				})
				// console.log(listPostpaidSKU);

				this.setState({ 
					listPostpaidSKU,
					listPostpaidBrand,
				})
			}
		})

		Get('bumdes/konfigurasi-profit/' + uuid, null, (dtkat) => {
			// console.log(dtkat.data);
			if (dtkat.data) {
				console.log(dtkat.data);

				this.fetch()

				let dataPrepaid = []
				let dataPostpaid = []

				// Jika data prepaid masih kosong
				if (dtkat.data.prepaid.trim() == '') { 
					// Get list data prepaid dari digiflazz
					Get('layanan/prepaid', null, (resData) => {
						if (resData.data) {
							let newDataPrepaid = []

							resData.data.map((val, index) => {
								let namaServer = val.buyer_sku_code.split('-')

								newDataPrepaid.push({
									brand: val.brand,
									product_name: val.product_name,
									buyer_sku_code: val.buyer_sku_code,
									category: val.category,
									// nama_server: namaServer[0],
									nominal_modal: parseInt(val.price),
									nominal_penjualan: 0,
									nominal_keuntungan: 0,
									nominal_het: parseInt(val.price) + 1000,
								})
							})

							let dataKonfig = dtkat.data

							let bodyRaw = {
								uuid: dataKonfig.uuid,
								prepaid: dataKonfig.prepaid,
								postpaid: dataKonfig.postpaid,
								level_akun: 'bumdes',
								uuid_pengguna: this.props.data.uuid,
							}
							// console.log(dataKonfig);
							// console.log(bodyRaw);

							bodyRaw.prepaid = JSON.stringify(newDataPrepaid)

							if (bodyRaw.uuid == '') {
								bodyRaw.uuid = null
							}

							// Cek jika data postpaid masih kosong juga
							if (dtkat.data.postpaid == '') {
								// Get list data postpaid dari digiflazz
								Get('layanan/postpaid', null, (resData) => {
									if (resData.data) {
										let newDataPostpaid = []

										resData.data.map((val, index) => {
											let namaServer = val.buyer_sku_code.split('-')

											newDataPostpaid.push({
												brand: val.brand,
												product_name: val.product_name,
												buyer_sku_code: val.buyer_sku_code,
												category: val.category,
												// nama_server: namaServer[0],
												nominal_modal: parseInt(val.price),
												nominal_penjualan: 0,
												nominal_keuntungan: 0,
												nominal_het: parseInt(val.price) + 1000,
											})
										})

										bodyRaw.postpaid = JSON.stringify(newDataPostpaid)

										Post('bumdes/konfigurasi-profit', bodyRaw.uuid, bodyRaw, (res) => {
											if (res.status === 200) {
												this.setState({
													show: true,
													basicType: 'success',
													basicTitle: 'Inisiasi Data Profit',
													pesanAlert: 'Berhasil Inisiasi Data Profit'
												});
												this.componentDidMount();
											} else {
												this.setState({
													show: true,
													basicType: 'danger',
													basicTitle: 'Inisiasi Data Profit',
													pesanAlert: 'Gagal Inisiasi Data Profit'
												});
											}
										});
					
									}
								})
							} else {
								Post('bumdes/konfigurasi-profit', bodyRaw.uuid, bodyRaw, (res) => {
									if (res.status === 200) {
										this.setState({
											show: true,
											basicType: 'success',
											basicTitle: 'Inisiasi Data Profit',
											pesanAlert: 'Berhasil Inisiasi Data Profit'
										});
										this.componentDidMount();
									} else {
										this.setState({
											show: true,
											basicType: 'danger',
											basicTitle: 'Inisiasi Data Profit',
											pesanAlert: 'Gagal Inisiasi Data Profit'
										});
									}
								});
							}
		
						}
					})
				}
				// Jika data prepaid sudah ada
				else if (dtkat.data.prepaid.trim() != '') {
					dataPrepaid = JSON.parse(dtkat.data.prepaid)
					this.setState({ dataPrepaid })

					// Jika data potspaid sudah ada
					if (dtkat.data.postpaid.trim() != '') {
						dataPostpaid = JSON.parse(dtkat.data.postpaid)
						console.log(dataPostpaid);
						this.setState({ dataPostpaid })
					} else if (dtkat.data.postpaid.trim() == '') { // Jika data postpaid belum ada
						// Get list data postpaid dari digiflazz
						Get('layanan/postpaid', null, (resData) => {
							if (resData.data) {
								let newDataPostpaid = []
	
								resData.data.map((val, index) => {
									let namaServer = val.buyer_sku_code.split('-')
	
									newDataPostpaid.push({
										brand: val.brand,
										product_name: val.product_name,
										buyer_sku_code: val.buyer_sku_code,
										category: val.category,
										// nama_server: namaServer[0],
										nominal_modal: parseInt(val.price),
										nominal_penjualan: 0,
										nominal_keuntungan: 0,
										nominal_het: parseInt(val.price) + 1000,
									})
								})
	
								let dataKonfig = dtkat.data
	
								let bodyRaw = {
									uuid: dataKonfig.uuid,
									prepaid: dataKonfig.prepaid,
									postpaid: dataKonfig.postpaid,
									level_akun: 'bumdes',
									uuid_pengguna: this.props.data.uuid,
								}
	
								bodyRaw.postpaid = JSON.stringify(newDataPostpaid)
	
								if (bodyRaw.uuid == '') {
									bodyRaw.uuid = null
								}
	
								Post('bumdes/konfigurasi-profit', bodyRaw.uuid, bodyRaw, (res) => {
									if (res.status === 200) {
										this.setState({
											show: true,
											basicType: 'success',
											basicTitle: 'Inisiasi Data Profit',
											pesanAlert: 'Berhasil Inisiasi Data Profit'
										});
										this.componentDidMount();
									} else {
										this.setState({
											show: true,
											basicType: 'danger',
											basicTitle: 'Inisiasi Data Profit',
											pesanAlert: 'Gagal Inisiasi Data Profit'
										});
									}
								});
			
							}
						})
					}

				}

				// Pengelompokan Menu Tabulasi dan filter
				const groupByCategory = groupBy('category');
				const groupByBrand = groupBy('brand');

				let detailListPrepaid = []
				let detailListPostpaid = []

				console.log(dataPrepaid);
				console.log(dataPostpaid);

				if (dataPrepaid.length > 0) {
					detailListPrepaid = groupByCategory(dataPrepaid);
					this.setState({ detailListPrepaid })
				}
				if (dataPostpaid.length > 0) {
					// detailListPostpaid = groupByCategory(dataPostpaid);
					detailListPostpaid = groupByBrand(dataPostpaid);
					console.log(detailListPostpaid);
					this.setState({ detailListPostpaid })
				}

				// let listPrepaid = [
				// 	'Pulsa',
				// 	'PLN',
				// ]
				
				// let listPostpaid = [
				// 	'PLN',
				// ]

				this.setState({ 
					dataKonfig: dtkat.data,
					// listPrepaid,
					// listPostpaid,
					loading: false
				});
				console.log(dtkat.data);
				console.log(this.state.dataKonfig);
			} else {
				this.setState({
					dataKonfig: null
				});
			}
		});
		
	}

	// ============================== Ubah Konfig ===============================

	ubahKonfig = (type_konfig) => {
		// e.preventDefault();
		switch (type_konfig) {
			case 'postpaid':
				this.setState({ konfig_postpaid: 'inherit' });
				break;
			case 'prepaid':
				this.setState({ konfig_prepaid: 'inherit' });
				break;
		}
		this.forceUpdate();
	};
	// ========================================================================

	// ============================== Toggle Proccess =========================
	handleChangeUmum(checked, jenis_konfig) {
		console.log('CHANGE JENIS Konfig');
		console.log(jenis_konfig);
		switch (jenis_konfig) {
			// LAYANAN POST
			case 'post_pulsa':
				if (this.state.post_pulsa == true) {
					this.state.post_pulsa = checked;
				} else {
					this.state.post_pulsa = checked;
				}
				break;
			case 'post_pln':
				if (this.state.post_pln == true) {
					this.state.post_pln = checked;
				} else {
					this.state.post_pln = checked;
				}
				break;
			case 'post_pdam':
				if (this.state.post_pdam == true) {
					this.state.post_pdam = checked;
				} else {
					this.state.post_pdam = checked;
				}
				break;
			case 'post_tv_kabel':
				if (this.state.post_tv == true) {
					this.state.post_tv = checked;
				} else {
					this.state.post_tv = checked;
				}
				break;

			// LAYANAN Pre
			case 'pre_pulsa':
				if (this.state.pre_pulsa == true) {
					this.state.pre_pulsa = checked;
				} else {
					this.state.pre_pulsa = checked;
				}
				break;
			case 'pre_pln':
				if (this.state.pre_pln == true) {
					this.state.pre_pln = checked;
				} else {
					this.state.pre_pln = checked;
				}
				break;
			case 'pre_pdam':
				if (this.state.pre_pdam == true) {
					this.state.pre_pdam = checked;
				} else {
					this.state.pre_pdam = checked;
				}
				break;
			case 'pre_tv_kabel':
				if (this.state.pre_tv == true) {
					this.state.pre_tv = checked;
				} else {
					this.state.pre_tv = checked;
				}
				break;
		}

		this.forceUpdate();
		this.setState({ checked });
	}
	// ============================== End Toggle Proccess =====================

	// ======================= SIMPAN Layanan =====================
	simpanLayanan = (e) => {
		// e.preventDefault();
		this.state.status.btnForm = true;
		let dataKonfigPre = '';
		let dataKonfigPost = '';
		let allKonfig = '';
		// if(this.state.konfig_postpaid=="inherit"){
		// dataKonfigPost = {
		// 	pulsa: this.state.post_pulsa,
		// 	pln: this.state.post_pln,
		// 	pdam: this.state.post_pdam,
		// 	tv_kabel: this.state.post_tv,
		// }
		// }
		dataKonfigPost = {
			pulsa: document.getElementById('post_pulsa').value,
			pln: document.getElementById('post_pln').value,
			pdam: document.getElementById('post_pdam').value,
			tv_kabel: document.getElementById('post_tv').value
		};

		// if(this.state.konfig_prepaid=="inherit"){
		// dataKonfigPre = {
		// 	pulsa: this.state.pre_pulsa,
		// 	pln: this.state.pre_pln,
		// 	pdam: this.state.pre_pdam,
		// 	tv_kabel: this.state.pre_tv,
		// }
		// }
		dataKonfigPre = {
			pulsa: document.getElementById('pre_pulsa').value,
			pln: document.getElementById('pre_pln').value,
			pdam: document.getElementById('pre_pdam').value,
			tv_kabel: document.getElementById('pre_tv').value
		};

		allKonfig = {
			// uuid: this.props.location.state.uuid,
			uuid: 'f90b37f9-8a85-436d-8c19-0fc9b6adc0c8',
			postpaid: JSON.stringify(dataKonfigPost),
			prepaid: JSON.stringify(dataKonfigPre)
		};

		this.forceUpdate();

		console.log('Data Yang Disimpan');
		console.log(dataKonfigPost);
		console.log(dataKonfigPre);
		console.log('Data Simpan Gabung');
		console.log(allKonfig);

		let psn = 'Ubah';
		let resstat = 200;

		Post('konfigurasi-admin', allKonfig.uuid, allKonfig, (res) => {
			console.log(res);
			this.forceUpdate();
			if (res.status === resstat) {
				if (this.state.konfig_postpaid == 'inherit') {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Konfigurasi Produk Digital',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.state.konfig_postpaid = 'none';
				}
				if (this.state.konfig_prepaid == 'inherit') {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Konfigurasi Produk DIgital',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.state.konfig_prepaid = 'none';
				}
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Konfigurasi Produk Digital',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};

	// ========================================================================

	/// upload file Banner

	processGbr = (d) => {
		// console.log(d[0].type);
		if (d.length == 1) {
			let type = d[0].type.split('/');
			if (
				type[1] == 'jpg' ||
				type[1] == 'jpeg' ||
				type[1] == 'png' ||
				type[1] == 'bmp' ||
				type[1] == 'tiff' ||
				type[1] == 'webp'
			) {
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				Post_Marketplace('upload-file/banner-ppob', null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
							this.setState({
								foto_desa: uri,
								foto_info: '',
								link_gambar: data.data.results
							});
						},
						'base64'
					);
				});
			} else {
				this.setState({
					foto_desa: '',
					foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
				});
			}
		} else {
			this.setState({
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
			});
		}
	};

	// ================== simpan data banner==================
	simpanBanner = (e) => {
		e.preventDefault();

		// let link_lama = JSON.parse(this.state.get_link_gambar);
		// let link_baru = this.state.link_gambar;
		// let newGetLinkALL = [];

		if (this.state.link_gambar == '') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Banner Desa',
				pesanAlert: 'Silahkan lengkapi data!'
			});
		} else {
			this.state.status.btnForm = true;
			this.forceUpdate();
			let uuidnya = '';

			let bodyRaw = {
				uuid: document.getElementById('uuid_banner').value,
				gambar: this.state.link_gambar,
				keterangan: document.getElementById('keterangan_banner').value
			};

			let psn = '';
			let resstat = 204;
			let metode = 'create';
			if (bodyRaw.uuid === '') {
				psn = 'Tambah';
				resstat = 200;
				bodyRaw.uuid = null;
			} else {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
				if (bodyRaw.gambar === '') {
					bodyRaw.gambar = this.state.link_gambar_lama;
				} else {
					axios.get(`${this.state.link_gambar_lama}/hapus`).then((resp) => {
						// console.log(resp.data);
					});

					bodyRaw.gambar = bodyRaw.gambar;
				}
			}

			console.log('Data SImpan Banner bos');
			console.log(bodyRaw);

			Post('banner', bodyRaw.uuid, bodyRaw, (res) => {
				this.state.status.btnForm = false;
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Banner Campaign',
						pesanAlert: 'Berhasil ' + psn + ' Data',
						link_gambar: '',
						data_detail_banner: []
					});
					this.state.status.formBanner = false;
					this.forceUpdate();
					this.componentDidMount();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Banner Campaign',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					this.componentDidMount();
				}
			});
		}
	};

	// ============================= End Simpan Banner =============================

	render() {
		var data = this.state.data;

		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'product_name',
				text: 'Product Name',
				sort: true,
				editable: false
			},
			{
				dataField: 'brand',
				text: 'Brand',
				sort: true,
				textAlign: "center",
				align: 'center',
				editable: false
			},
			{
				dataField: 'buyer_sku_code',
				text: 'SKU Code',
				sort: true,
				editable: false
			},
			{
				dataField: 'nominal_modal',
				text: 'Harga Modal',
				sort: true,
				editable: false,
				formatter: (cell, obj) => {
					let nominal_modal = new Intl.NumberFormat(['ban', 'id']).format(obj.nominal_modal)
					return 'Rp. ' + nominal_modal
				}
			},
			// {
			// 	dataField: 'nominal_penjualan',
			// 	text: 'Nominal Penjualan',
			// 	sort: true,
			// 	editable: true
			// },
			{
				dataField: 'nominal_keuntungan',
				text: 'Nominal Profit',
				sort: true,
				editable: true,
				formatter: (cell, obj) => {
					let nominal_keuntungan = new Intl.NumberFormat(['ban', 'id']).format(obj.nominal_keuntungan)
					return 'Rp. ' + nominal_keuntungan
				}
			},
			{
				dataField: 'nominal_het',
				text: 'HET',
				sort: true,
				editable: true,
				formatter: (cell, obj) => {
					let nominal_het = new Intl.NumberFormat(['ban', 'id']).format(obj.nominal_het)
					return 'Rp. ' + nominal_het
				}
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
				editable: false
			}
		];

		const columns2 = [
			{
				dataField: 'product_name',
				text: 'Product Name',
				sort: true,
				editable: false
			},
			{
				dataField: 'brand',
				text: 'Brand',
				sort: true,
				textAlign: "center",
				align: 'center',
				editable: false
			},
			{
				dataField: 'buyer_sku_code',
				text: 'SKU Code',
				sort: true,
				editable: false
			},
			{
				dataField: 'nominal_keuntungan',
				text: 'Nominal Profit',
				sort: true,
				editable: true,
				formatter: (cell, obj) => {
					let nominal_keuntungan = new Intl.NumberFormat(['ban', 'id']).format(obj.nominal_keuntungan)
					return 'Rp. ' + nominal_keuntungan
				}
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi,
				editable: false
			}
		];
		
		const columns3 = [
			{
				dataField: 'CreatedAt',
				text: 'Tanggal',
				sort: true,
				formatter: (cell, obj) => {
					let date = moment(new Date(obj.CreatedAt))
					// return date.format("YYYY-MM-DD HH:mm:ss")
					let html = (
						<>
							<span style={{ display: "block" }}>
								{date.format("YYYY-MM-DD")}
							</span>
							<span style={{ display: "block" }}>
								{date.format("HH:mm:ss")}
							</span>
						</>
						// date.format("YYYY-MM-DD") + '<br>' + date.format("HH:mm:ss")
					)
					return html
				}
			},
			{
				dataField: 'jenis_transaksi',
				text: 'Jenis Transaksi',
				sort: true
			},
			{
				dataField: 'type_transaksi',
				text: 'Tipe Transaksi',
				sort: true
			},
			{
				dataField: 'TransaksiDetails.tipe_transaksi_detail',
				text: 'Detail Tipe Transaksi',
				sort: true
			},
			{
				dataField: 'nominal',
				text: 'Nominal Penjualan',
				sort: true,
				formatter: (cell, obj) => {
					let nominal = new Intl.NumberFormat(['ban', 'id']).format(obj.nominal)
					return 'Rp. ' + nominal
				}
			},
			{
				dataField: 'TransaksiDetails.harga_untung_bumdes',
				text: 'Profit',
				sort: true,
				formatter: (cell, obj) => {
					let nominal_profit = new Intl.NumberFormat(['ban', 'id']).format(obj.TransaksiDetails.harga_untung_bumdes)
					return 'Rp. ' + nominal_profit
				}
			},
			{
				dataField: 'keterangan',
				text: 'Keterangan',
				sort: true
			},
		];

		let listBrand = null
		let listServer = [
			{value: 'A1', label: 'A1'},
			{value: 'A2', label: 'A2'},
			{value: 'A3', label: 'A3'},
		]
		let listSKU = []

		if (this.state.detailData && this.state.detailData.category) {
			let cat = this.state.detailData.category
			
			listSKU = this.state.listPrepaidSKU[cat] ? this.state.listPrepaidSKU[cat] : this.state.listPostpaidSKU[cat]

			listBrand = this.state.listPrepaidBrand[cat] ? this.state.listPrepaidBrand[cat] : this.state.listPostpaidBrand[cat]

			// switch (cat.toLowerCase()) {
			// 	case 'pulsa':
			// 		listBrand = [
			// 			{value: 'TELKOMSEL', label: 'TELKOMSEL'},
			// 			{value: 'TRI', label: 'TRI'},
			// 			{value: 'XL', label: 'XL'},
			// 			{value: 'AXIS', label: 'AXIS'},
			// 			{value: 'INDOSAT', label: 'INDOSAT'},
			// 		]
			// 		break;

			// 	case 'pln':
			// 		listBrand = [
			// 			{value: 'PLN', label: 'PLN'},
			// 		]
			// 		break;
				
			// 	case 'games':
			// 		listBrand = [
			// 			{value: 'Free Fire', label: 'Free Fire'},
			// 			{value: 'Higgs Domino', label: 'Higgs Domino'},
			// 			{value: 'Mobile Legend', label: 'Mobile Legend'},
			// 			{value: 'PUBG Mobile', label: 'PUBG Mobile'},
			// 		]
			// 		break;

			// 	case 'pln pascabayar':
			// 		listBrand = [
			// 			{value: 'PLN PASCABAYAR', label: 'PLN PASCABAYAR'},
			// 		]
			// 		break;

			// 	case 'bpjs kesehatan':
			// 		listBrand = [
			// 			{value: 'BPJS KESEHATAN', label: 'BPJS KESEHATAN'},
			// 		]
			// 		break;

			// 	case 'hp pascabayar':
			// 		listBrand = [
			// 			{value: 'HP PASCABAYAR', label: 'HP PASCABAYAR'},
			// 		]
			// 		break;

			// 	case 'internet pascabayar':
			// 		listBrand = [
			// 			// {value: 'SPEEDY & INDIHOME', label: 'SPEEDY & INDIHOME'},
			// 			// {value: 'TELKOMPSTN', label: 'TELKOMPSTN'},
			// 			{value: 'INTERNET PASCABAYAR', label: 'INTERNET PASCABAYAR'},
			// 		]
			// 		break;

			// 	case 'multifinance':
			// 		listBrand = [
			// 			{value: 'MULTIFINANCE', label: 'MULTIFINANCE'},
			// 			// {value: 'Columbia Finance', label: 'Columbia Finance'},
			// 			// {value: 'Mega Auto Finance', label: 'Mega Auto Finance'},
			// 			// {value: 'Mega Central Finance', label: 'Mega Central Finance'},
			// 			// {value: 'Wom Finance', label: 'Wom Finance'},
			// 			// {value: 'Mega Finance', label: 'Mega Finance'},
			// 			// {value: 'Acc Finance', label: 'Acc Finance'},
			// 			// {value: 'Aeon Cicilan', label: 'Aeon Cicilan'},
			// 			// {value: 'Allianz Asuransi', label: 'Allianz Asuransi'},
			// 			// {value: 'Artha Prima Finance', label: 'Artha Prima Finance'},
			// 			// {value: 'Acc (Astra Credit Company-Astra Sedaya Finance)', label: 'Acc (Astra Credit Company-Astra Sedaya Finance)'},
			// 			// {value: 'Bca Multifinance', label: 'Bca Multifinance'},
			// 			// {value: 'PT BFI Finance Indonesia INDONESIA', label: 'PT BFI Finance Indonesia INDONESIA'},
			// 			// {value: 'Blibli.com Tagihan di bawah 1jt', label: 'Blibli.com Tagihan di bawah 1jt'},
			// 			// {value: 'Blibli.com Tagihan di atas 1jt', label: 'Blibli.com Tagihan di atas 1jt'},
			// 			// {value: 'Buana Finance', label: 'Buana Finance'},
			// 			// {value: 'Capella Multidana', label: 'Capella Multidana'},
			// 			// {value: 'CIMB Niaga JCB', label: 'Columbia'},
			// 			// {value: 'CIMB Niaga Visa', label: 'CIMB Niaga Visa'},
			// 			// {value: 'Clipan Finance', label: 'Clipan Finance'},
			// 			// {value: 'Angsuran Columbia', label: 'Angsuran Columbia'},
			// 			// {value: 'Home Credit Indonesia', label: 'Home Credit Indonesia'},
			// 			// {value: 'Heksa Insurance Premi Lanjutan', label: 'Heksa Insurance Premi Lanjutan'},
			// 			// {value: 'Angsuran Kredit Plus(Finansia)', label: 'Angsuran Kredit Plus(Finansia)'},
			// 			// {value: 'Pembayaran PT. Suzuki Finance', label: 'Pembayaran PT. Suzuki Finance'},
			// 			// {value: 'SMS Finance', label: 'SMS Finance'},
			// 			// {value: 'Smart Multi Finance', label: 'Smart Multi Finance'},
			// 			// {value: 'Radana Finance / HD Finance', label: 'Radana Finance / HD Finance'},
			// 			// {value: 'Prudential Premi Pertama', label: 'Prudential Premi Pertama'},
			// 			// {value: 'Prudential', label: 'Prudential'},
			// 			// {value: 'Pro Mitra Finance', label: 'Pro Mitra Finance'},
			// 			// {value: 'Pro Car International Finance', label: 'Pro Car International Finance'},
			// 			// {value: 'OTO Kredit Mobil/Motor', label: 'OTO Kredit Mobil/Motor'},
			// 			// {value: 'NSC Finance', label: 'NSC Finance'},
			// 			// {value: 'Nissan Finance', label: 'Nissan Finance'},
			// 			// {value: 'Multindo Auto Finance', label: 'Multindo Auto Finance'},
			// 			// {value: 'MNC Finance', label: 'MNC Finance'},
			// 			// {value: 'Mandiri Utama Finance', label: 'Mandiri Utama Finance'},
			// 			// {value: 'Mandiri Tunas Finance', label: 'Mandiri Tunas Finance'},
			// 			// {value: 'Premi Jiwasraya', label: 'Premi Jiwasraya'},
			// 			// {value: 'Jaccs MPM Finance Indonesia', label: 'Jaccs MPM Finance Indonesia'},
			// 			// {value: 'Angsuran ITC Multi Finance', label: 'Angsuran ITC Multi Finance'},
			// 			// {value: 'Indomobil Finance Indonesia', label: 'Indomobil Finance Indonesia'},
			// 			// {value: 'CIMB Niaga Master Card', label: 'CIMB Niaga Master Card'},
			// 		]
			// 		break;
			// }
		}
		
		let bootstrapTable = (category, type) => (
			<div>
				<ToolkitProvider 
					keyField="uuid_surat" 
					data={type == 'prepaid' ? this.state.detailListPrepaid[category] : this.state.detailListPostpaid[category] }
					columns={type == 'prepaid' ? columns : columns2}
					search
				>
					{
						 props => (
							<div>
								<div className="row">
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-8" style={{ marginBottom: "15px" }}>
												<Button
													size="sm"
													color="success"
													className="btn-square"
													style={{ marginBottom: '15px' }}
													onClick={() => {
														if (type == 'prepaid') {
															this.setState({
																judul: 'Tambah Data Profit ' + category,
																// currentPrepaidIndex: null,
																currentDetailIndex: null,
																// detailPrepaid: {...this.state.detailPrepaid, category},
																detailData: {...this.state.detailData, category},
															});
														} else {
															this.setState({
																judul: 'Tambah Data Profit ' + category + ' ' + type,
																// currentPostpaidIndex: null,
																currentDetailIndex: null,
																// detailPostpaid: {...this.state.detailPostpaid, category},
																detailData: {...this.state.detailData, category},
															});
														}
														this.bukaForm();
													}}
												>
													Tambah Data
												</Button>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row" style={{ justifyContent: "flex-end" }}>
											<div className="col-md-8" style={{ display: "flex", justifyContent: "flex-end" }}>
												<SearchBar { ...props.searchProps } />
											</div>
										</div>
									</div>
								</div>
								<Row>
									<Col sm="12">
										<BootstrapTable
											pagination={ paginationFactory({
												sizePerPage: 10
											}) }
											{ ...props.baseProps }
											cellEdit={ cellEditFactory({
												mode: 'dbclick',
												onStartEdit: (oldValue, newValue, row, column) => {
													let bodyRaw = {}
													if (this.state.dataKonfig) {
														bodyRaw = {
															uuid: this.state.dataKonfig.uuid,
															prepaid: this.state.dataKonfig.prepaid,
															postpaid: this.state.dataKonfig.postpaid,
															level_akun: 'bumdes',
															uuid_pengguna: this.props.data.uuid,
														}
													}
													
													let isiData = null
													switch (this.state.active_tab_vertical) {
														case '1':
															isiData = JSON.parse(bodyRaw.prepaid)
															break;
														case '2':
															isiData = JSON.parse(bodyRaw.postpaid)
															break;
													}

													// let currentDetailIndex = row
													let currentDetailIndex = isiData.map(function(e) { return e.buyer_sku_code; }).indexOf(oldValue.buyer_sku_code);
													this.setState({ currentDetailIndex })
												},
												afterSaveCell: (oldValue, newValue, row, column) => {
													let oldData = row
													let dataField = column.dataField
													this.editableCellHandle(oldData, dataField, newValue)
												}							
											}) }
										/>
									</Col>
								</Row>
							</div>
						)
					}
				</ToolkitProvider>
				{/* {data.length != 0 ? (
					<div className="pull-right text-white">
						{this.state.awal}
						{this.state.sebelum}
						{this.state.hal.map((dt) => {
							return dt;
						})}
						{this.state.setelah}
						{this.state.akhir}
					</div>
				) : (
					''
				)} */}
			</div>
		);

		let bootstrapTable2 = (
			<div>
				<BootstrapTable keyField="uuid" data={this.state.dataRekapProfit} columns={columns3} />
				<div className="pull-right text-white">
					{this.state.awal}
					{this.state.sebelum}
					{this.state.hal.map((dt) => {
						return dt;
					})}
					{this.state.setelah}
					{this.state.akhir}
				</div>
			</div>
		)

		return (
			<div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>
				{this.state.alert}

				<div className="row">
					<div className="col-sm-3 col-xs-12">
						<Nav  className={'nav flex-column nav-pills'}>
							<NavItem>
								<NavLink className={classnames({ active: this.state.active_tab_vertical === '1' })}
										onClick={() => { this.toggle_vertical('1'); }}>
									Prepaid
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={classnames({ active: this.state.active_tab_vertical === '2' })}
										onClick={() => { this.toggle_vertical('2'); }}>
									Postpaid
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className={classnames({ active: this.state.active_tab_vertical === '3' })}
										onClick={() => { this.toggle_vertical('3'); }}>
									Rekapitulasi Profit
								</NavLink>
							</NavItem>
						</Nav>
					</div>
					<div className="col-sm-9 col-xs-12">
						<TabContent activeTab={this.state.active_tab_vertical}>
							{/* Prepaid */}
							<TabPane tabId="1">
								<Nav tabs className="tabs-color">
									{Object.keys(this.state.listPrepaidSKU).map((category, index) => {
										return (
											<NavItem style={{ cursor: 'pointer' }}>
												<NavLink
													className={classnames({
														active: this.state.active_tab_icon === (index + 1).toString()
													})}
													onClick={() => {
														this.toggle_icon((index + 1).toString());
													}}
												>
													<i className="icofont icofont-ui-home" />
													{category}
												</NavLink>
											</NavItem>
										)
									})}
								</Nav>
								<TabContent activeTab={this.state.active_tab_icon}>
									{Object.keys(this.state.listPrepaidSKU).map((category, index) => {
										return (
											<TabPane tabId={(index + 1).toString()}>
												<div style={{ marginTop: 15 }}>
													{this.state.detailListPrepaid && typeof this.state.detailListPrepaid[category] != 'undefined' ? bootstrapTable(category, 'prepaid') : (
														<div className="row">
															<div className="col-sm-4">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	style={{ marginBottom: '15px' }}
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Data Profit ' + category,
																			// currentPrepaidIndex: null,
																			currentDetailIndex: null,
																			// detailPrepaid: {...this.state.detailPrepaid, category},
																			detailData: {...this.state.detailData, category},
																		});
																		this.bukaForm();
																	}}
																>
																	Tambah Data
																</Button>
															</div>
														</div>
													)}
												</div>
											</TabPane>
										)
									})}
								</TabContent>
							</TabPane>
							{/* Postpaid */}
							<TabPane tabId="2">
								<Nav tabs className="tabs-color">
									{Object.keys(this.state.listPostpaidSKU).map((category, index) => {
										return (
											<NavItem style={{ cursor: 'pointer' }}>
												<NavLink
													className={classnames({
														active: this.state.active_tab_icon === (index + 1).toString()
													})}
													onClick={() => {
														this.toggle_icon((index + 1).toString());
													}}
												>
													<i className="icofont icofont-ui-home" />
													{category}
												</NavLink>
											</NavItem>
										)
									})}
								</Nav>
								<TabContent activeTab={this.state.active_tab_icon}>
									{Object.keys(this.state.listPostpaidSKU).map((category, index) => {
										return (
											<TabPane tabId={(index + 1).toString()}>
												<div style={{ marginTop: 15 }}>
													{this.state.detailListPostpaid && typeof this.state.detailListPostpaid[category] != 'undefined' ? bootstrapTable(category, 'postpaid') : (
														<div className="row">
															<div className="col-sm-4">
																<Button
																	size="sm"
																	color="success"
																	className="btn-square"
																	style={{ marginBottom: '15px' }}
																	onClick={() => {
																		this.setState({
																			judul: 'Tambah Data Profit ' + category,
																			currentDetailIndex: null,
																			detailData: {...this.state.detailData, category},
																		});
																		this.bukaForm();
																	}}
																>
																	Tambah Data
																</Button>
															</div>
														</div>
													)}
												</div>
											</TabPane>
										)
									})}
								</TabContent>
							</TabPane>
							{/* Rekap Profit Bumdes */}
							<TabPane tabId="3">
								<div style={{ marginTop: 15 }}>
									<div className="row" style={{ marginBottom: 5 }}>
										<div className="col-sm-4">
											<Datetime
												input={true}
												timeFormat={false}
												dateFormat="DD/MM/yyyy"
												closeOnSelect={true}
												initialValue={this.state.tanggal_mulai}
												value={this.state.tanggal_mulai}
												onChange={(val) => {
													console.log(val);
													// this.setState({ tanggal_mulai: new Date(val) })
													this.filterData('mulai', val)
												}}
												inputProps={{
													placeholder: 'Tanggal mulai',
													className: 'form-control mb-15'
												}}
											/>
										</div>
										<div className="col-sm-2" style={{ paddingTop: 10, paddingBottom: 10 }}>
											<h6 style={{ textAlign: "center" }}>Sampai</h6>
										</div>
										<div className="col-sm-4">
											<Datetime
												input={true}
												timeFormat={false}
												dateFormat="DD/MM/yyyy"
												closeOnSelect={true}
												initialValue={this.state.tanggal_selesai}
												value={this.state.tanggal_selesai}
												onChange={(val) => {
													console.log(val);
													// this.setState({ tanggal_selesai: new Date(val) })
													this.filterData('selesai', val)
												}}
												inputProps={{
													placeholder: 'Tanggal selesai',
													className: 'form-control mb-15'
												}}
											/>
										</div>
										<div className="col-lg-2">
											<Button
												className="btn btn-danger btn-success btn-md"
												onClick={(val) => {
													
													switch (this.state.active_tab_icon) {
														case '1':
															this.setState({ 
																loading: true,
																data: [],
															})
															break;
														case '2':
															this.setState({ 
																loading: true,
																dataPending: [],
															})
															break;
														case '3':
															this.setState({ 
																loading: true,
																dataGagal: [],
															})
															break;
														case '4':
															this.setState({ 
																loading: true,
																dataSelesai: [],
															})
															break;
													
														default:
															break;
													}

													this.fetch({ tab: this.state.active_tab_icon })

													setTimeout(function () {
														this.setState({ 
															tanggal_mulai: null,
															tanggal_selesai: new Date(),
															loading: false
														})
													}.bind(this), 1)
												}}
											>
												Reset
											</Button>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12">
											{this.state.active_tab_vertical == '3' ? bootstrapTable2 : ''}
										</div>
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
				</div>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={this.tutupForm}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
						<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Buyer SKU Code
								</Label>
								<Col sm="9">
									<Select
										classNamePrefix="select"
										onChange={e => {
											// Prepaid
											if (this.state.active_tab_vertical == '1') {
												let data = this.state.SKUPrepaid[e.value][0]
												
												this.setState({
													detailData: {...this.state.detailData,
														brand: data.brand,
														buyer_sku_code: e.value,
													}
												})
												document.getElementById('product_name').value = data.product_name
												document.getElementById('nominal_modal').value = data.price
												document.getElementById('nominal_het').value = data.price + 1000
											} else {
												let data = this.state.SKUPostpaid[e.value][0]
												
												console.log(data);
												this.setState({
													detailData: {...this.state.detailData,
														brand: data.brand,
														buyer_sku_code: e.value,
													}
												})
												document.getElementById('product_name').value = data.product_name
											}
										}}
										defaultValue={this.state.detailData.buyer_sku_code != '' ? {
											value : this.state.detailData.buyer_sku_code,
											label : this.state.detailData.buyer_sku_code,
										} : null}
										name="buyer_sku_code"
										options={listSKU}
										placeholder="Pilih SKU"
										isClearable
										disabled
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Product Name
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="product_name"
										type="text"
										placeholder="Product Name"
										required
										defaultValue={this.state.detailData.product_name}
										style={{ backgroundColor: "#c9d1d8" }}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Brand
								</Label>
								<Col sm="9">
									<Select
										classNamePrefix="select"
										onChange={e => {
											this.changeSelectValue(e, "brand");
										}}
										defaultValue={this.state.detailData.brand != '' ? {
											value : this.state.detailData.brand,
											label : this.state.detailData.brand,
										} : null}
										value={this.state.detailData.brand != '' ? {
											value : this.state.detailData.brand,
											label : this.state.detailData.brand,
										} : null}
										name="brand"
										// options={[this.state.listBrand[category]]}
										options={listBrand}
										placeholder="Brand"
										isClearable
										isDisabled
									/>
								</Col>
							</FormGroup>
							
							{this.state.active_tab_vertical != '2' ? (
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										{/* Nominal Penjualan */}
										Harga Modal
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nominal_modal"
											type="number"
											placeholder="Masukkan Harga Modal (Contoh : 10285)"
											required
											defaultValue={this.state.detailData.nominal_modal}
											readOnly
											style={{ backgroundColor: "#c9d1d8" }}
										/>
									</Col>
								</FormGroup>
							) : ''}
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nominal Keuntungan
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nominal_keuntungan"
										type="number"
										placeholder="Masukkan Nominal Keuntungan / Profit"
										required
										defaultValue={this.state.detailData.nominal_keuntungan}
									/>
								</Col>
							</FormGroup>
							{this.state.active_tab_vertical == '1' ? (
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Harga Eceran Tertinggi
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nominal_het"
											type="number"
											placeholder="Masukkan Harga Eceran Tertinggi"
											required
											defaultValue={this.state.detailData.nominal_het ? this.state.detailData.nominal_het : ''}
										/>
									</Col>
								</FormGroup>
							) : ''}
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
			
		)

	}
}

export default DetailProfitBumdes;
