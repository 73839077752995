import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Datepicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';

import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col,Nav,NavItem, NavLink, TabContent, TabPane  } from 'reactstrap';
import classnames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { PostStatus, Post, Get, Get_Marketplace, Post_Marketplace, Delete_Marketplace, Delete, cekLogin, JENIS_DESA} from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from 'reactdatepicker';
import DetailProfitBumdes from './sub-bumdes/detail-profit-bumdes';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: '',
	username: '',
	password: '',
	nik: 0,
	id_role: '',
	nama_lengkap: '',
	alamat: '',
	status: null,
	no_hp: null,
	email: null
};

class Bumdes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_detail: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				statGet: false,
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kelStat: null,
			pendStat: [],
			frmPen: [],
			jpStat: [],
			frmJp: [],
			formPenduduk: 'none',
			dataVerifikasi: [],
			photoIndex: 0,
			isOpen: false,
			idVerifikasi: null,
			dataReset: {
				uuid: '',
				username: ''
			},
			jenisUser: [],
			id_jenisUser: null,
			tingkatUser: [],
			id_tingkatUser: null,

			// Stat Form
			stat_form: false,

			// List Bumdes
			listBumdes: [],
			// Set desa bumdes
			getdesaBumdes: null,
			detailBumdes: null,

			// Loading
			loading: true,

			// Search
			url_cari: "",

			// Tab Pane
			active_tab_icon: '1',
		};
	}


	// Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			switch (tab) {
				case '1':
					this.fetch({ page: null, where: [], tab: '1' });
					break;

				case '2':
					this.fetch({ page: null, where: [], tab: '2' });
					break;

				default:
					this.fetch({ page: null, where: [], tab: '1' });
					break;
			}

			this.setState({
				active_tab_icon: tab
			});
		}
	}

	// ================== DatePicker ==================
	gantiTglMulai = (date) => {
		this.state.dt.tanggal_mulai = date;
		this.forceUpdate();
	};
	gantiTglSelesai = (date) => {
		this.state.dt.tanggal_selesai = date;
		this.forceUpdate();
	};
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}


		let link_cari = ""
		if(this.state.url_cari == ""){
			link_cari = ""
		}
		else{
			link_cari = "&"+ this.state.url_cari
		}

		let link_status=""
		// if(params.tab == "1"){
		// 	link_status = "&status=Aktif"
		// }
		// else{
		// 	link_status = "&status=Tidak Aktif"
		// }

		switch (params.tab) {
			
			case '1':
				link_status = "&status=Aktif"
				break;

			case '2':
				link_status = "&status=Tidak Aktif"
				break;

			default:
				link_status = "&status=Aktif"
				break;
		}

		Get("bumdes?page_size=10" + link + link_status + link_cari, null, dtkat => {
			// console.log("Data Pengguna")
			// console.log(dtkat)
			if(dtkat.data){
				this.setState({ data: dtkat.data.data, loading: false, pagination: dtkat.data });
				this.pagination(dtkat.data, params.tab);
			}
			else{
				this.setState({
					data: [],
					loading: false, 
					pagination: dtkat.data
				});
			}
		});
	};

	// Pagination
	pagination = (data,tab) => {
		// const data = this.state.pagination;
		console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i, tab })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};
	// Cari Data Table
	cariData = (e, tab) => {
		if (e.key == 'Enter') {
			this.state.loading = true

			// this.forceUpdate();
			// let addRole = {
			// 	q: e.target.value
			// };
			// Get('bumdes?keyword=' + e.target.value, null, (dtkat) => {
			// 	if(dtkat.data){
			// 		this.setState({
			// 			data: dtkat.data.data,
			// 			loading: false, 
			// 			pagination: dtkat.data
			// 		});
			// 	}
			// 	else{
			// 		this.setState({
			// 			data: [],
			// 			loading: false, 
			// 			// pagination: dtkat
			// 		});
			// 	}
			// });
			
			this.state.url_cari = "keyword="+e.target.value
			this.state.active_tab_icon = tab
			this.forceUpdate();
			this.componentDidMount()
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		// if (this.state.getdesaBumdes == '' || this.state.getdesaBumdes == null) {
		if (!this.state.getdesaBumdes) {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data BUMDES',
				pesanAlert: 'Pilih Kelurahan / Desa terlebih dahulu.'
			});

		} 
		else if (document.getElementById("uuid").value=="" && document.getElementById("password").value == '') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data BUMDES',
				pesanAlert: 'Masukkan Password Terlebih Dahulu'
			});

		} else {
			let addPengguna = {
				uuid: document.getElementById("uuid").value,
				username: document.getElementById("username").value,
				no_hp: document.getElementById("no_hp").value,
				email: document.getElementById("email").value,
				role: "2",
				// id_desa: this.state.getdesaBumdes,
				id_desa: this.state.getdesaBumdes.value,
			};

			let cek_pass = document.getElementById("password").value

			let psn2 = '';
			let resstat2 = 204;
			let metode2 = "create";
			if (addPengguna.uuid === '') {
				psn2 = "Tambah";
				resstat2 = 201;
				addPengguna.uuid = null;
				addPengguna.password = document.getElementById("password").value
			}
			else {
				psn2 = "Ubah";
				resstat2 = 200;
				metode2 = "update";
				addPengguna.uuid = this.state.data_detail.uuid_marketplace
				if (cek_pass != '') {
					addPengguna.password = document.getElementById("password").value
				}
			}

			console.log("Data Simpan Marketplace ni bos")
			console.log(addPengguna)
			Post_Marketplace('bumdes/' + metode2, addPengguna.uuid, addPengguna, (res2) => {
				console.log("Simpan Akun Marketplacenya")
				console.log(res2)
				if (res2.status === 422) {
					console.log("Username atau Email telah terdaftar Marketplace")
					console.log(res2.status)
					this.setState({ show: true, basicType:'warning', basicTitle:'Data BUMDES', pesanAlert:'Username atau Email telah terdaftar' });
				}
				else if (res2.status === 403) {
					console.log("BUMDES di desa ini telah terdaftar Marketplace")
					console.log(res2.status)
					this.setState({ show: true, basicType:'warning', basicTitle:'Data BUMDES', pesanAlert:'BUMDES di desa ini telah terdaftar' });
				}
				else if (res2.status === resstat2) {
					console.log("Berhasil, Lanjut PPOB Marketplace")
					console.log(res2.status)
					let bodyRaw = {
						uuid : document.getElementById("uuid").value,
						username: document.getElementById("username").value,
						password: document.getElementById("password").value,
						// id_desa: this.state.getdesaBumdes,
						id_desa: this.state.getdesaBumdes.value,
						pin: "",
						role: "bumdes",
						nik: "",
						nama_lengkap: '',
						alamat: document.getElementById("alamat_bumdes").value,
						nama_bumdes: document.getElementById("nama_bumdes").value
					}

					let psn = '';
					let resstat = 204;
					let metode = "create";
					if (bodyRaw.uuid === '') {
						psn = "Tambah";
						resstat = 200;
						bodyRaw.uuid = null;
						bodyRaw.status_verifikasi = "Verifikasi";
					}
					else {
						psn = "Ubah";
						resstat = 200;
						metode = "update";
						bodyRaw.status_verifikasi = this.state.data_detail.status_verifikasi;
					}

					Post('bumdes', bodyRaw.uuid, bodyRaw, (res) => {
						console.log("Simpan Bumdesnya PPOB")
						console.log(res)
						if(res.status == 422){
							// Hapus Data di Marketplace
							// Get_Marketplace('bumdes/desa', bodyRaw.id_desa, (lihat) => {
							// 	let id_marketplace = lihat.result.uuid

							// 	Delete_Marketplace('bumdes/delete', id_marketplace, (res_marketplace) => {
							// 		if (res_marketplace == 200) {
							// 			this.setState({
							// 				show: true,
							// 				basicType: 'success',
							// 				basicTitle: 'Data Bumdes',
							// 				pesanAlert: 'Berhasil ' + psn + ' Data'
							// 			});
							// 		}
							// 		else {
							// 			this.setState({ show: true, basicType: 'danger', basicTitle: 'Data Koperasi', pesanAlert: 'Gagal ' + psn + ' Data' });
							// 		}
							// 		this.componentDidMount();
							// 	})
							// });

							Get_Marketplace('bumdes/desa', bodyRaw.id_desa, (lihat) => {
								let id_marketplace = lihat.result.uuid

								Delete_Marketplace('bumdes/delete', id_marketplace, (res_marketplace) => {
									if (res_marketplace == 200) {
										this.setState({
											show: true,
											basicType: 'warning',
											basicTitle: 'Data Bumdes',
											pesanAlert: 'Username atau Email telah terdaftar'
										});
									}
									else {
									}
									this.componentDidMount();
								})
							});
						} 
						else if(res.status == 403){
							// Hapus Data di Marketplace
							Get_Marketplace('bumdes/desa', bodyRaw.id_desa, (lihat) => {
								let id_marketplace = lihat.result.uuid

								Delete_Marketplace('bumdes/delete', id_marketplace, (res_marketplace) => {
									if (res_marketplace == 200) {
										this.setState({
											show: true,
											basicType: 'warning',
											basicTitle: 'Data Bumdes',
											pesanAlert: 'Username atau Email telah terdaftar'
										});
									}
									else {
									}
									this.componentDidMount();
								})
							});
						} 
						else if (res.status === resstat) {
							console.log("Sukses")
							console.log(res)
							this.setState({ show: true, basicType:'success', basicTitle:'Data BUMDES', pesanAlert:'Berhasil ' + psn + ' Data' });
							this.state.status.form = false;
							// this.state.getdesaBumdes = "";
							this.state.getdesaBumdes = null;
							this.state.stat_form = false;
							this.state.data_detail = [];
							this.forceUpdate();
							this.componentDidMount()
						}
						else {
							// Hapus Data di Marketplace
							Get_Marketplace('bumdes/desa', bodyRaw.id_desa, (lihat) => {
								let id_marketplace = lihat.result.uuid

								Delete_Marketplace('bumdes/delete', id_marketplace, (res_marketplace) => {
									if (res_marketplace == 200) {
										this.setState({ show: true, basicType:'danger', basicTitle:'Data BUMDES', pesanAlert:'Gagal ' + psn + ' Data' });
									}
									else {
									}
									this.componentDidMount();
								})
							});
							// console.log("Error")
							// console.log(res)
							// this.setState({ show: true, basicType:'danger', basicTitle:'Data BUMDES', pesanAlert:'Gagal ' + psn + ' Data' });
						}
					})
				}
				else {
					console.log("Gagal Marketplace")
					console.log(res2.status)
					this.setState({ show: true, basicType:'danger', basicTitle:'Data BUMDES', pesanAlert:'Gagal ' + psn2 + ' Data' });
				}
				this.componentDidMount();
			});
		}
	};
	// ========================================================================

	// ================== simpan data ==================
	simpanReset = (id) => {
		console.log(id);
		if (id) {
			Get('pengguna/reset-password/' + id, null, (res) => {
				this.forceUpdate();
				console.log(res);
				if (res) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Berhasil reset Data'
					});
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Pengguna',
						pesanAlert: 'Gagal reset Data'
					});
				}
				this.tutupForm();

				this.componentDidMount();
			});
		} else {
			this.setState({
				show: true,
				basicType: 'danger',
				basicTitle: 'Data Pengguna',
				pesanAlert: 'Gagal reset Data'
			});
		}
	};
	// =
	// ============================== Ubah Data ===============================

	ubahData = (data) => {

		console.log(data);
		this.state.stat_form = false;
		this.state.data_detail.uuid = data.uuid;
		this.state.data_detail.id_desa = data.id_desa;
		// this.state.getdesaBumdes = data.id_desa;
		this.state.getdesaBumdes = {
			value: data.DataDesa.id,
			label: data.DataDesa.nama,
		};
		this.state.data_detail.nama_bumdes = data.nama_bumdes;
		this.state.data_detail.username = data.username;
		this.state.data_detail.alamat = data.alamat;
		this.state.data_detail.role = data.role;
		this.state.data_detail.status_verifikasi = data.status_verifikasi;
		
		Get_Marketplace('bumdes/desa', data.id_desa, (lihat) => {
			console.log("Get Detail Data Desa")
			console.log(lihat.result)
			if (lihat.result) {
				this.setState({
					data_detail: {
						uuid_marketplace: lihat.result.uuid,
						email: lihat.result.email,
						no_hp: lihat.result.no_hp
					}
				});
			}
		});

		this.state.status.btnAksi = false;
		this.forceUpdate();
		this.bukaForm();
		// });
	};

	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (data) => {
		let psn = ""
		if(data.status_akun=="Aktif"){
			this.setState({
				alert: (
					<SweetAlert
						showCancel
						confirmBtnText="Nonaktif"
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="primary"
						type="warning"
						title="Yakin ingin nonaktif bumdes ?"
						onCancel={this.hideAlert}
						onConfirm={() => this.hapusData(data)}
					>
						Proses ini tidak dapat dibatalkan!
					</SweetAlert>
				)
			});
		}
		else{
			this.setState({
				alert: (
					<SweetAlert
						showCancel
						confirmBtnText="Aktif"
						confirmBtnBsStyle="success"
						cancelBtnBsStyle="primary"
						type="warning"
						title="Yakin ingin aktif bumdes ?"
						onCancel={this.hideAlert}
						onConfirm={() => this.hapusData(data)}
					>
						Proses ini tidak dapat dibatalkan!
					</SweetAlert>
				)
			});
		}
	};

	hapusData = (data) => {
		this.hideAlert();
		let psn = '';
		let tap = '';
		let bodyRaw = {
			uuid : data.uuid,
		}
		
		if(data.status_akun == "Aktif"){
			bodyRaw.status_akun = "Tidak Aktif"
			psn = "Nonaktif"
			tap = "1"
		}
		else{
			bodyRaw.status_akun = "Aktif"
			psn = "Aktif"
			tap = "2"
		}
		// Hapus Data di PPOB
		
		Post('bumdes/aktivasi-akun', bodyRaw.uuid, bodyRaw, (res) => {
			if (res.status == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Bumdes',
					pesanAlert: 'Berhasil '+psn+' Bumdes',
					active_tab_icon: tap,
				});
				this.forceUpdate();
				this.componentDidMount();
			}
			else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Bumdes',
					pesanAlert: 'Gagal '+psn+' Bumdes',
					active_tab_icon: tap,
				});
			}
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.dt.uuid = '';
		this.state.dt.username = '';
		this.state.dt.password = '';
		this.state.dt.alamat = '';
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.kelStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;

		// this.state.getdesaBumdes = "";
		this.state.getdesaBumdes = null;
		this.state.data_detail = [];

		this.state.status.form = false;
		this.state.stat_form = false;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		// console.log(row);
		return (
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
				<Button
					size="xs"
					color="primary"
					className="btn-icon"
					onClick={() => {
						this.setState({
							judul: 'Ubah BUMDES',
							statUbah: false
						});
						this.ubahData(row);
					}}
					disabled={this.state.status.btnAksi}
					title="Edit"
				>
					<i className="fa fa-pencil"></i>
				</Button>
				<Button
					size="xs"
					color="info"
					className="btn-icon"
					onClick={() => {
						this.setState({
							judul: 'Detail Profit BUMDES',
							detailBumdes: row
						});
					}}
					disabled={this.state.status.btnAksi}
					title="Detail Profit"
				>
					<i className="fa fa-eye"></i>
				</Button>
				{
					row.status_akun == "Aktif"
					?
						(
							<Button
								size="xs"
								color="danger"
								className="btn-icon"
								onClick={() => this.konfirmHapus(row)}
								disabled={this.state.status.btnAksi}
								title="Nonaktif"
							>
								<i className="fa fa-user-times"></i>
							</Button>
						)
					:
						(
							<Button
								size="xs"
								color="success"
								className="btn-icon"
								onClick={() => this.konfirmHapus(row)}
								disabled={this.state.status.btnAksi}
								title="Aktif"
							>
								<i className="fa fa-user-plus"></i>
							</Button>
						)
				}
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
				case 'keldes':
					// let idDus = this.state.dusStat;
					// this.setState({ kelStat: e });
					// if (this.state.id_kategori_penduduk.value == 2) {
					// 	this.setState({ dusStat: idDus });
					// } else {
					// 	this.setState({ dusStat: null });
					// }
					this.setState({ kelStat: e });
					this.setState({ dusStat: null });

					break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// console.log(this.state.rtStat)
					break;
				case 'ukuran_kertas':
					this.state.dt.id_ukuran_kertas = e.value;
					this.setState({ ukkStat: e });
					break;

				case 'shdk':
					this.state.dt.shdk = e.value;
					this.setState({ shdkStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.value;
					this.setState({ agmStat: e });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = e.value;
					this.setState({ ptrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.value;
					this.setState({ pkrStat: e });
					break;
				case 'kk':
					this.state.dt.id_kk = e.value;
					this.setState({ kkStat: e });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = e.value;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = e.value;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = e.value;
					this.setState({ spkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;

				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: null });
					this.setState({ kbktStat: null, frmKbkt: [] });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'kecamatan':
					this.setState({ kecStat: null });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'keldes':
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
				case 'shdk':
					this.state.dt.shdk = 0;
					this.setState({ shdkStat: null });
					break;
				case 'agama':
					this.state.dt.agama = 0;
					this.setState({ agmStat: null });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = 0;
					this.setState({ ptrStat: null });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = 0;
					this.setState({ pkrStat: null });
					break;
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = 0;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = 0;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = '';
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: null,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	// componentDidMount(tab = '1') {
	componentDidMount(tab = '1') {
		console.log("Tabnya berapa")
		console.log(tab)
		let tabActive = this.state.active_tab_icon;

		if (typeof this.props.location.tabProps != 'undefined') {
			this.state.active_tab_icon = this.props.location.tabProps;
			tabActive = this.props.location.tabProps.toString();
		}
		if (typeof this.props.location.state != 'undefined') {
			this.state.active_tab_icon = this.props.location.state.tab;
		}


		switch (tabActive) {
			case '1':
				this.fetch({ page: null, where: [], tab: '1' });
				break;

			case '2':
				this.fetch({ page: null, where: [], tab: '2' });
				break;

			default:
				this.fetch({ page: null, where: [], tab: '1' });
				break;
		}

		Get_Marketplace('list-api/list', null, (lihat) => {
			console.log("Data List Desa")
			console.log(lihat)
			console.log(lihat.result)
			
			let listBumdes = []

			if (lihat.result) {
				lihat.result.map((val) => {
					listBumdes.push({ value: val.id_desa, label: val.data_desa.nama })
				})
				this.setState({
					// listBumdes: lihat.result
					listBumdes
				});
			}
		});

		// Get("provinsi/list", null, data => {
		// 	console.log(data)
		//   let frmPrv = [];
		//   data.results.map(dt => {
		//     frmPrv.push({ value: dt.id, label: dt.nama });
		//   });
		//   this.setState({ frmPrv });
		// });
		// Get("select-enum/pengguna/jenis", null, data => {
		//   console.log("Data Jenis User")
		//   console.log(data)
		//   let jenisUser = [];
		//   data.map(dt => {
		//     jenisUser.push({ value: dt, label: dt });
		//   });
		//   this.setState({ jenisUser });
		// });
		// Get("select-enum/pengguna/tingkat", null, data => {
		//   console.log("Data Tingkatan User")
		//   console.log(data)
		//   let tingkatUser = [];
		//   data.map(dt => {
		//     tingkatUser.push({ value: dt, label: dt });
		//   });
		//   this.setState({ tingkatUser });
		// });
	}

	ChangeListBumdes = (e) => {
		if (e) {
			this.setState({ getdesaBumdes: e.target.value });

		}
		else {
			this.setState({ getdesaBumdes: '' });
		}
	}

	render() {
		console.log("Tampilkan")
		console.log(this.state.active_tab_icon)
		// console.log("Data Detail")
		// console.log(this.state.data_detail)
		// console.log(this.state.id_jenisUser)
		// console.log(this.state.prvStat)
		// console.log(this.state.kbktStat)
		// console.log(this.state.kecStat)
		const { photoIndex, isOpen } = this.state;

		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;

		// var data = require('../assets/json/company');

		const frmDef = {
			uuid: '',
			username: '',
			password: '',
			nik: '',
			id_role: '',
			status: null,
			no_hp: null,
			email: null
		};
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'nama_bumdes',
				text: 'Nama BUMDES',
				sort: true
			},
			{
				dataField: 'username',
				text: 'Username',
				sort: true
			},
			{
				dataField: 'DataDesa.nama',
				text: 'Nama Desa',
				sort: true
			},
			{
				dataField: 'alamat',
				text: 'Alamat',
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		return (
			<div>
				{/* <Breadcrumb title="Penguna" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									{!this.state.detailBumdes ? (
										<div>
											<Nav tabs className="tabs-color">
												<NavItem style={{ cursor: 'pointer' }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '1'
														})}
														onClick={() => {
															this.toggle_icon('1');
														}}
													>
														<i className="icofont icofont-ui-home" />
														Aktif
													</NavLink>
												</NavItem>
												<NavItem style={{ cursor: 'pointer' }}>
													<NavLink
														className={classnames({
															active: this.state.active_tab_icon === '2'
														})}
														onClick={() => {
															this.toggle_icon('2');
														}}
													>
														<i className="icofont icofont-man-in-glasses" />
														Tidak Aktif
													</NavLink>
												</NavItem>
											</Nav>
											<TabContent activeTab={this.state.active_tab_icon}>
												<TabPane tabId="1">
													<div style={{ marginTop: 15 }}>
														<Row>
															<Col sm="12">
															<div className="row" style={{ flexDirection: "row-reverse" }}>
																<div className="col-sm-6 text-right">
																	<Button
																		size="sm"
																		color="success"
																		className="btn-square"
																		style={{ marginBottom: '15px' }}
																		onClick={() => {
																			this.setState({
																				judul: 'Tambah BUMDES',
																				dt: frmDef,
																				getdesaBumdes: null
																			});
																			this.state.data_detail.uuid = null
																			this.state.stat_form = true;
																			this.forceUpdate();
																			this.bukaForm();
																		}}
																	>
																		Tambah BUMDES
																	</Button>
																</div>
																<div className="col-sm-6">
																	<input
																		type="text"
																		className="form-control"
																		id="cariTable"
																		placeholder="Cari Disini"
																		onKeyPress={(e) => this.cariData(e, this.state.active_tab_icon)}
																		style={{ marginBottom: '15px' }}
																	/>
																</div>
															</div>
															{ this.state.loading 
																? 
																	<div align="center">
																		<br/><br/>
																		<img 
																		src={require("../assets/images/loading-bos.gif")} 
																		style={{
																			width: "100%",
																			borderRadius: "10px",
																			width: '70px'
																		}}/> 
																	</div>
																:
																	<>
																	<BootstrapTable keyField="id" data={data} columns={columns} />
																	<div className="pull-right text-white">
																		{this.state.awal}
																		{this.state.sebelum}
																		{this.state.hal.map((dt) => {
																			return dt;
																		})}
																		{this.state.setelah}
																		{this.state.akhir}
																	</div>
																	</>
															}
															</Col>
														</Row>
													</div>
												</TabPane>
												<TabPane tabId="2">
													<div style={{ marginTop: 15 }}>
														<Row>
															<Col sm="12">
																<div className="row" style={{ flexDirection: "row-reverse" }}>
																	<div className="col-sm-6 text-right">
																		&nbsp;
																	</div>
																	<div className="col-sm-6">
																		<input
																			type="text"
																			className="form-control"
																			id="cariTable"
																			placeholder="Cari Disini"
																			onKeyPress={(e) => this.cariData(e, this.state.active_tab_icon)}
																			style={{ marginBottom: '15px' }}
																		/>
																	</div>
																</div>
																{ this.state.loading 
																	? 
																		<div align="center">
																			<br/><br/>
																			<img 
																			src={require("../assets/images/loading-bos.gif")} 
																			style={{
																				width: "100%",
																				borderRadius: "10px",
																				width: '70px'
																			}}/> 
																		</div>
																	:
																		<>
																		<BootstrapTable keyField="id" data={data} columns={columns} />
																		<div className="pull-right text-white">
																			{this.state.awal}
																			{this.state.sebelum}
																			{this.state.hal.map((dt) => {
																				return dt;
																			})}
																			{this.state.setelah}
																			{this.state.akhir}
																		</div>
																		</>
																}
															</Col>
														</Row>
													</div>
												</TabPane>
											</TabContent>
										</div>
									) : (
										<div>
											<div className="row">
												<div className="col-sm-10">
													<h5>{this.state.detailBumdes.nama_bumdes} (Pengaturan Profit)</h5>
												</div>
												<div className="col-sm-2" style={{ display: "flex", justifyContent: "flex-end" }}>
													<Button
														size="md"
														color="info"
														className="btn-icon"
														onClick={() => {
															this.setState({
																detailBumdes: null
															});
															// this.ubahDataBanner(row);
														}}
														// disabled={this.state.status.btnAksi}
													>
														<i className="fa fa-left-arrow" /> Kembali
													</Button>
												</div>
											</div>
											<DetailProfitBumdes data={this.state.detailBumdes} />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal
					size="lg"
					open={this.state.status.form}
					onClose={this.tutupForm}
					styles={{ modal: { width: '80%' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div size="lg" className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.data_detail.uuid}
							/>

					{
						this.state.stat_form
						?
							<>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Desa
									</Label>
									<Col sm="9">
									
										{/* <select
											className="form-control"
											name="id_desa"
											value={this.state.getdesaBumdes}
											onChange={this.ChangeListBumdes}
										>
											<option value="">Pilih Desa</option>
											{this.state.listBumdes.map((e, key) => {
												return <option key={key} value={e.id_desa}>{e.data_desa.nama}</option>;
											})}
										</select> */}
										<Select
											classNamePrefix="select"
											onChange={e => {
												this.setState({ getdesaBumdes: e })
											}}
											defaultValue={this.state.getdesaBumdes}
											options={this.state.listBumdes}
											placeholder="Pilih Desa"
											isClearable
										/>
									</Col>
								</FormGroup>
							</>
							: 
							<>
							</>
						}
							
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama BUMDES
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_bumdes"
										type="text"
										placeholder="Masukkan Nama BUMDES"
										required
										defaultValue={this.state.data_detail.nama_bumdes}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									No. Handphone
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_hp"
										type="text"
										placeholder="Masukkan Nomor Handphone"
										required
										defaultValue={this.state.data_detail.no_hp}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Email
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="email"
										type="text"
										placeholder="Masukkan Email"
										defaultValue={this.state.data_detail.email}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat BUMDES
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="alamat_bumdes"
										type="text"
										placeholder="Masukkan Alamat BUMDES"
										required
										defaultValue={this.state.data_detail.alamat}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Username
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="username"
										type="text"
										placeholder="Masukkan Username"
										required
										defaultValue={this.state.data_detail.username}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Password
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="password"
										type="password"
										placeholder="Masukkan Password"
										// required
										defaultValue={this.state.dt.password}
									/>
								</Col>
							</FormGroup>

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Provinsi
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'provinsi', 'prov', 'kab-kota');
										}}
										defaultValue={this.state.prvStat}
										value={this.state.prvStat}
										name="provinsi"
										options={this.state.frmPrv}
										placeholder="Pilih Provinsi"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kabupaten / Kota
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
										}}
										defaultValue={this.state.kbktStat}
										value={this.state.kbktStat}
										name="kabkota"
										options={this.state.frmKbkt}
										placeholder="Pilih Kabupaten/Kota"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kecamatan
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kecamatan', 'kec', 'keldes');
										}}
										defaultValue={this.state.kecStat}
										value={this.state.kecStat}
										name="kecamatan"
										options={this.state.frmKec}
										placeholder="Pilih Kecamatan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kelurahan / {JENIS_DESA}
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.changeSelectValue(e, 'keldes');
										}}
										// defaultValue={this.state.kldsStat}
										// value={this.state.kldsStat}
										defaultValue={this.state.kelStat}
										value={this.state.kelStat}
										name="keldes"
										options={this.state.frmKel}
										placeholder={'Pilih Kelurahan/' + JENIS_DESA}
										isClearable
									/>
								</Col>
							</FormGroup> */}

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									No. HP
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_hp"
										type="number"
										placeholder="Masukkan Nomor HP"
										required
										defaultValue={this.state.dt.no_hp}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Email
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="email"
										type="email"
										placeholder="Masukkan e-mail"
										required
										defaultValue={this.state.dt.email}
									/>
								</Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="alamat"
										type="text"
										placeholder="Masukkan alamat"
										required
										defaultValue={this.state.dt.alamat}
									/>
								</Col>
							</FormGroup> */}
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Bumdes;
