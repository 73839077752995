import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Datepicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';

import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Switch from "react-switch";

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from 'reactdatepicker';
import classnames from 'classnames';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: null,
	akronim: '',
	kode_bank: '',
	nama: '',
	status: '',
	petunjuk_pembayaran: [],
};

class Bank extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dataTidakAktif: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				statGet: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			active_tab_icon: '1',
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kelStat: null,
			pendStat: [],
			frmPen: [],
			jpStat: [],
			frmJp: [],
			formPenduduk: 'none',
			dataVerifikasi: [],
			photoIndex: 0,
			isOpen: false,
			idVerifikasi: null,
			dataReset: {
				uuid: '',
				username: ''
			},
			jenisUser: [],
			id_jenisUser: null,
			tingkatUser: [],
			id_tingkatUser: null,

			loading: true,
			cariKata: '',
		};
	}

	// ================== DatePicker ==================
	gantiTglMulai = (date) => {
		this.state.dt.tanggal_mulai = date;
		this.forceUpdate();
	};
	gantiTglSelesai = (date) => {
		this.state.dt.tanggal_selesai = date;
		this.forceUpdate();
	};
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	inputChange = (event) => {
		console.log(event.target.value);
		this.setState({ 
			dt: {...this.state.dt, 
				petunjuk_pembayaran: {...this.state.dt.petunjuk_pembayaran, 
					judul: event.target.value
				}
			}
		})
		console.log(this.state.dt);
	}

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}

		Get('bank?' + '&keyword=' + this.state.cariKata + link, null, (dtkat) => {
			console.log(dtkat);
			if (dtkat.data && dtkat.data.data) {
				this.setState({ 
					data: dtkat.data.data, 
					loading: false, 
					pagination: dtkat.data 
				});
				this.pagination(dtkat.data, params.tab);
			} else {
				this.setState({ 
					data: [], 
					loading: false, 
					pagination: dtkat.data 
				});
			}
		});
	};

	// Pagination
	pagination = (data, tab) => {
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i, tab })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
		console.log(awal);
		console.log(sebelum);
		console.log(hal);
		console.log(setelah);
		console.log(akhir);
	};
	// Cari Data Table
	cariData = (e, tab) => {
		if (e.key == 'Enter') {
			this.state.loading = true;
			this.state.cariKata = e.target.value;
			this.forceUpdate();
			let addRole = {
				q: e.target.value
			};


			Get('bank?keyword=' + e.target.value , null, (dtkat) => {
				console.log(dtkat)
				if (dtkat.data.data) {
					switch (tab) {
						case '1':
							this.setState({
								data: dtkat.data.data,
								loading: false,
								pagination: dtkat.data
							});
							break;
						case '2':
							this.setState({
								dataTidakAktif: dtkat.data.data,
								loading: false,
								pagination: dtkat.data
							});
							break;
					
						default:
							break;
					}
				} else {
					switch (tab) {
						case '1':
							this.setState({
								data: [],
								loading: false,
								pagination: dtkat.data
							});
							break;
						case '2':
							this.setState({
								dataTidakAktif: [],
								loading: false,
								pagination: dtkat.data
							});
							break;
					
						default:
							break;
					}
				}
				this.pagination(dtkat.data, tab);
			});
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		let bodyRaw = {
			uuid: document.getElementById('uuid').value,
			akronim: document.getElementById('akronim').value,
			kode_bank: document.getElementById('kode_bank').value,
			nama: document.getElementById('nama').value,
			status: 'Aktif',
			petunjuk_pembayaran: JSON.stringify(this.state.dt.petunjuk_pembayaran)
		};

		let psn = 'Tambah';
		let resstat = 200;
		let metode = 'create';

		if (bodyRaw.uuid != '') {
			psn = 'Ubah';
			resstat = 200;
			metode = 'update';
		} else {
			bodyRaw.uuid = null;
		}

		console.log(this.state.dt.petunjuk_pembayaran);
		console.log(bodyRaw);

		Post('bank', bodyRaw.uuid, bodyRaw, (res) => {
			console.log(res);
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Bank',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
				this.componentDidMount();
				this.tutupForm();
			} else {
				let message = 'Gagal ' + psn + ' Data'

				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Bank',
					pesanAlert: message
				});
			}
		});
	};
	// ========================================================================

	// =
	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		console.log(data);
		// console.log(JSON.parse(data.petunjuk_pembayaran));
		// this.state.dt.id_wilayah = data.id_wilayah;
		// this.state.dt.jenis = data.jenis;
		// this.state.dt.nip = data.nip;
		// this.state.dt.status = data.status;
		// this.state.dt.tingkat = data.tingkat;

		let petunjuk_pembayaran = [
			{
				judul: '',
				isi: ['']
			}
		]
		if (data.petunjuk_pembayaran != '') {
			petunjuk_pembayaran = JSON.parse(data.petunjuk_pembayaran)
		}

		this.state.dt.uuid = data.uuid;
		this.state.dt.akronim = data.akronim;
		this.state.dt.kode_bank = data.kode_bank;
		this.state.dt.nama = data.nama;
		this.state.dt.status = data.status;
		// this.state.dt.petunjuk_pembayaran = JSON.parse(data.petunjuk_pembayaran);
		this.state.dt.petunjuk_pembayaran = petunjuk_pembayaran;

		this.state.status.btnAksi = false;
		this.forceUpdate();
		this.bukaForm();
		// });
	};

	konfirmUpdateStatus = (uuid, status) => {
		let statusText = status === 'aktif' ? 'menonaktifkan' : 'mengaktifkan'
		console.log(status);
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Iya"
					cancelBtnText="Batal"
					confirmBtnBsStyle="warning"
					cancelBtnBsStyle="success"
					type="warning"
					title={"Apakah anda yakin ingin " + statusText + " bank ini ?"}
					onCancel={this.hideAlert}
					onConfirm={() => this.updateStatus(uuid, status)}
				>
					{/* Proses ini tidak dapat dibatalkan! */}
				</SweetAlert>
			)
		});
	};

	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';

		let bodyRaw = {
			uuid: id
		};

		Delete('bank', bodyRaw, (res) => {
			console.log(res);
			if (res == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Bank',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
				this.componentDidMount();
				this.setState({ status: { ...this.state.status, form: false } });
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Bank',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	updateStatus = (uuid, status) => {
		let bodyRaw = {
			uuid : uuid, 
			status : status === 'aktif' ? 'Tidak Aktif' : 'Aktif'
		}

		Post('bank', bodyRaw.uuid, bodyRaw, (res) => {
			console.log(res);
			if (res.status === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Bank',
					pesanAlert: 'Berhasil ubah status bank',
					alert: null
				});
				this.componentDidMount();
				this.tutupForm();
			} else {
				let message = 'Gagal ubah status bank'

				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Bank',
					pesanAlert: message,
					alert: null
				});
			}
		});
	}

	tutupForm = () => {
		this.state.dt.uuid = null;
		this.state.dt.username = '';
		this.state.dt.password = '';
		this.state.dt.alamat = '';
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.kelStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;
		this.state.cariKata = '';

		this.state.status.form = false;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		console.log(row);
		// let status = row.status.toLowerCase()

		return (
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => {
						this.setState({
							judul: 'Ubah Bank',
							statUbah: false
						});
						this.ubahData(row);
					}}
					title="Edit"
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>
				{' '}
				<Button
					size="xs"
					// color={status === 'aktif' ? 'danger' : 'info'}
					color={'danger'}
					className="btn-icon"
					// onClick={() => this.konfirmUpdateStatus(row.uuid, status)}
					onClick={() => this.konfirmHapus(row.uuid)}
					disabled={this.state.status.btnAksi}
					style={{ marginLeft: '5px' }}
					title="Hapus"
				>
					<i className="fa fa-trash" title={'Hapus'} />
				</Button>
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
				case 'keldes':
					// let idDus = this.state.dusStat;
					// this.setState({ kelStat: e });
					// if (this.state.id_kategori_penduduk.value == 2) {
					// 	this.setState({ dusStat: idDus });
					// } else {
					// 	this.setState({ dusStat: null });
					// }
					this.setState({ kelStat: e });
					this.setState({ dusStat: null });

					break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// console.log(this.state.rtStat)
					break;
				case 'ukuran_kertas':
					this.state.dt.id_ukuran_kertas = e.value;
					this.setState({ ukkStat: e });
					break;

				case 'shdk':
					this.state.dt.shdk = e.value;
					this.setState({ shdkStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.value;
					this.setState({ agmStat: e });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = e.value;
					this.setState({ ptrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.value;
					this.setState({ pkrStat: e });
					break;
				case 'kk':
					this.state.dt.id_kk = e.value;
					this.setState({ kkStat: e });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = e.value;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = e.value;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = e.value;
					this.setState({ spkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;

				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: null });
					this.setState({ kbktStat: null, frmKbkt: [] });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'kecamatan':
					this.setState({ kecStat: null });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'keldes':
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
				case 'shdk':
					this.state.dt.shdk = 0;
					this.setState({ shdkStat: null });
					break;
				case 'agama':
					this.state.dt.agama = 0;
					this.setState({ agmStat: null });
					break;
				case 'pendidikan-terakhir':
					this.state.dt.pendidikan_terakhir = 0;
					this.setState({ ptrStat: null });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = 0;
					this.setState({ pkrStat: null });
					break;
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null });
					break;
				case 'status-penduduk':
					this.state.dt.status_penduduk = 0;
					this.setState({ stpStat: e });
					break;
				case 'jk':
					this.state.dt.jk = 0;
					this.setState({ jkStat: e });
					break;
				case 'status-perkawinan':
					this.state.dt.status_perkawinan = '';
					this.setState({ spkStat: e });
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
				case 'jenisUser':
					this.setState({ id_jenisUser: e });
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: null,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			switch (tab) {
				case '1':
					this.fetch({ page: null, where: [], tab: '1' })
					break;
				case '2':
					this.fetch({ page: null, where: [], tab: '2' })
					break;
			}

			this.setState({
				active_tab_icon: tab
			});
		}
	}

	componentDidMount(tab = '1') {
		this.fetch({ page: null, where: [], tab });
	}

	render() {
		var data = this.state.data;

		// console.log(data);

		// var data = require('../assets/json/company');

		const frmDef = {
			uuid: null,
			akronim: '',
			kode_bank: '',
			nama: '',
			status: '',
			petunjuk_pembayaran: [],
		};
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'akronim',
				text: 'Akronim',
				sort: true,
			},
			{
				dataField: 'kode_bank',
				text: 'Kode Bank',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama Bank',
				sort: true
			},
			// {
			// 	dataField: 'status',
			// 	text: 'Status',
			// 	sort: true
			// },
			// {
			//   dataField: "tingkat",
			//   text: "Tingkat",
			//   sort: true
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		let bootstrapTable = (
			<div>
				<BootstrapTable keyField="uuid" data={data} columns={columns} />
				<div className="pull-right text-white">
					{this.state.awal}
					{this.state.sebelum}
					{this.state.hal.map((dt) => {
						return dt;
					})}
					{this.state.setelah}
					{this.state.akhir}
				</div>
			</div>
		)

		return (
			<div>
				{/* <Breadcrumb title="Penguna" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<div className="row" style={{ flexDirection: 'row-reverse', marginTop: 15 }}>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												style={{ marginBottom: '15px' }}
												onClick={() => {
													this.setState({
														judul: 'Tambah Bank',
														dt: frmDef,
														prvStat: null,
														kbktStat: null,
														kecStat: null,
														kldsStat: null,
														kelStat: null
													});
													this.state.dt.uuid = null;
													this.state.id_tingkatUser = null;
													this.state.id_jenisUser = null;
													this.forceUpdate();
													this.bukaForm();
												}}
											>
												Tambah Bank
											</Button>
										</div>
										<div className="col-sm-6">
											<input
												type="text"
												className="form-control"
												id="cariTable"
												placeholder="Cari Bank"
												onKeyPress={(e) => this.cariData(e, this.state.active_tab_icon)}
												style={{ marginBottom: '15px' }}
											/>
										</div>
									</div>

									{this.state.loading ? (
										<div align="center">
											<img
												src={require('../assets/images/loading-bos.gif')}
												style={{
													width: '100%',
													borderRadius: '10px',
													width: '70px'
												}}
											/>
										</div>
									) : (
										<div className="row">
											<div className="col-sm-12">
												{bootstrapTable}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal
					size="lg"
					open={this.state.status.form}
					onClose={this.tutupForm}
					styles={{ modal: { width: '80%', maxWidth: 'unset' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div size="lg" className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Akronim
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="akronim"
										type="text"
										placeholder="Masukkan Akronim (Contoh : BNI, BRI, BCA)"
										required
										defaultValue={this.state.dt.akronim}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kode Bank
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="kode_bank"
										type="number"
										placeholder="Masukkan Kode Bank (Contoh : 076)"
										required
										defaultValue={this.state.dt.kode_bank}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row" style={{ marginBottom: '5px' }}>
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Bank
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Masukkan Nama Bank (Contoh : Bank Negara Indonesia)"
										required
										defaultValue={this.state.dt.nama}
									/>
								</Col>
							</FormGroup>
							{this.state.dt.petunjuk_pembayaran.length > 0 ? (
								<>
									<FormGroup className="row" style={{ marginBottom: '5px' }}>
										<Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
											<b>Petunjuk Pembayaran</b>
										</Label>
									</FormGroup>
									{this.state.dt.petunjuk_pembayaran.map((dti, i) => {
										return (
											<div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
												{/* Tombol hapus petunjuk */}
												<div className="row" style={{ marginBottom: '10px' }}>
													<div className="col-sm-6">
														<button
															className="btn btn-danger"
															// disabled={this.state.status.btnForm}
															onClick={() => {
																let petunjuk_pembayaran = [...this.state.dt.petunjuk_pembayaran]
																petunjuk_pembayaran.splice(i, 1)
																this.setState({ 
																	dt: {...this.state.dt,
																		petunjuk_pembayaran
																	}
																})
															}}
														>
															Hapus petunjuk ini
														</button>
													</div>
												</div>
												<FormGroup className="row" style={{ marginTop: '0px', marginBottom: '10px' }} >
													<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
														<b>Judul {i+1}</b>
													</Label>
													<Col sm="8">
														<input
															className="form-control"
															type="text"
															placeholder="Masukkan Judul Petunjuk Pembayaran"
															required
															defaultValue={dti.judul}
															onChange={(event) => {
																let petunjuk_pembayaran = [...this.state.dt.petunjuk_pembayaran]
																petunjuk_pembayaran[i] = {...petunjuk_pembayaran[i], judul: event.target.value}

																this.setState({ 
																	dt: {...this.state.dt, 
																		petunjuk_pembayaran
																	}
																})
															}}
														/>
													</Col>
												</FormGroup>
												{dti.isi.map((dt_isi, j) => {
													return (
														<>
															<FormGroup className="row" style={{ marginBottom: '10px' }}>
																<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
																	Petunjuk {j+1}
																</Label>
																<Col sm="8">
																	<input
																		className="form-control"
																		type="text"
																		placeholder="Masukkan Detail Petunjuk Pembayaran"
																		required
																		defaultValue={dt_isi}
																		onChange={(event2) => {
																			let isi = [...this.state.dt.petunjuk_pembayaran[i].isi]
																			isi[j] = event2.target.value

																			let petunjuk_pembayaran = [...this.state.dt.petunjuk_pembayaran]
																			petunjuk_pembayaran[i] = {...petunjuk_pembayaran[i], isi}
			
																			this.setState({ 
																				dt: {...this.state.dt, 
																					petunjuk_pembayaran
																				}
																			})
																		}}
																	/>
																</Col>
																{j > 0 ? (
																	<Col lg="1">
																		<button
																			className="btn btn-danger"
																			title="Hapus detail petunjuk ini"
																			style={{ padding: '6px 15px' }}
																			onClick={() => {
																				let isi = [...this.state.dt.petunjuk_pembayaran[i].isi]
																				isi.splice(j, 1)

																				let petunjuk_pembayaran = [...this.state.dt.petunjuk_pembayaran]
																				petunjuk_pembayaran[i] = {...petunjuk_pembayaran[i], isi}
				
																				this.setState({ 
																					dt: {...this.state.dt, 
																						petunjuk_pembayaran
																					}
																				})
																			}}
																		>-</button>
																	</Col>
																) : ''}
															</FormGroup>
														</>
													)
												})}
												<div className="row">
													<div className="col-sm-12">
														<button
															type="button"
															className="btn btn-info"
															// disabled={this.state.status.btnForm}
															onClick={() => {
																let isi = [...this.state.dt.petunjuk_pembayaran[i].isi]
																isi.push('')

																let petunjuk_pembayaran = [...this.state.dt.petunjuk_pembayaran]
																petunjuk_pembayaran[i].isi = isi
																this.setState({ 
																	dt: {...this.state.dt,
																		petunjuk_pembayaran
																	}
																})
															}}
														>
															Tambah Detail Petunjuk
														</button>
													</div>
												</div>
											</div>
										)
									})}
								</>
							) : ''}
							<div className="row" style={{ marginTop: '15px' }}>
								<div className="col-sm-12">
									<button
										type="button"
										className="btn btn-info"
										// disabled={this.state.status.btnForm}
										onClick={() => {
											let petunjuk_pembayaran = [...this.state.dt.petunjuk_pembayaran]
											petunjuk_pembayaran.push({
												judul: '',
												isi: ['']
											})
											this.setState({ 
												dt: {...this.state.dt,
													petunjuk_pembayaran
												}
											})
										}}
									>
										Tambah Petunjuk Pembayaran
									</button>
								</div>
							</div>
							
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Bank;
