import React, { Component } from "react";

// Import custom components
import Header from "./common/header/header.component";
// import Customizer from './common/customizer.component';

import { Link, Redirect } from "react-router-dom";
import { DataToken } from "../function/Format";
// import { JENIS_DESA } from '../function/Koneksi';

import Sidebar from "./common/sidebar/sidebar.component";

const jwt = require("jsonwebtoken");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      layanan: [],
      perizinan: [],
      menuUtama: "inherit",
      menuWilayah: "none",
      menuPengaturan: "none",
      menuPerangkat: "none",
      menuLaporan: "none",
      menuAdministrasi: "none",
      menuPerubahanData: "none",
      akun: DataToken(sessionStorage.getItem("access_token")),
    };
  }
  bukaMenu = (wil) => {
    switch (wil) {
      case "laporan":
        this.setState({ menuUtama: "none", menuLaporan: "inherit" });
        break;
      case "wilayah":
        this.setState({ menuUtama: "none", menuWilayah: "inherit" });
        break;
      case "perangkat":
        this.setState({ menuUtama: "none", menuPerangkat: "inherit" });
        break;
      case "pengaturan":
        this.setState({ menuUtama: "none", menuPengaturan: "inherit" });
        break;

      case "perubahan_data":
        this.setState({
          menuUtama: "none",
          menuAdministrasi: "none",
          menuPerubahanData: "inherit",
        });
        break;
      case "administrasi":
        this.setState({
          menuUtama: "none",
          menuPerubahanData: "none",
          menuAdministrasi: "inherit",
        });
        break;
      case "utama":
        this.setState({
          menuPengaturan: "none",
          menuPerangkat: "none",
          menuWilayah: "none",
          menuLaporan: "none",
          menuAdministrasi: "none",
          menuPerubahanData: "none",
          menuUtama: "inherit",
        });
        break;
    }
  };
  render() {
    // console.log(this.state.akun)
    // if (this.state.akun == null) {
    // 	return <Redirect to="/" push={true} />;
    // }

    const menuWithRole = () => {
      return (
        <div className="row" style={{ display: this.state.menuUtama }}>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/kartu-keluarga"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-file-o"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>
                  Kartu Keluarga
                </p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/penduduk"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-users"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>Penduduk</p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <a
              href="javascript:void"
              onClick={() => {
                this.bukaMenu("administrasi");
              }}
            >
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-envelope-o"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>Administrasi</p>
              </div>
            </a>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/data-potensi"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-bar-chart-o"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>
                  Data Potensi Masyarakat
                </p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/catatan"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-edit"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>Catatan</p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/berita"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="icofont icofont-newspaper"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>
                  Berita/Pengumuman
                </p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            {/* <a
							href="javascript:void"
							onClick={() => {
								this.bukaMenu('laporan');
							}}
						>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-files-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Laporan</p>
							</div>
						</a> */}
            <Link to={process.env.PUBLIC_URL + "/admin/laporan"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-files-o"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>Laporan</p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/laporan-potensi"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-files-o"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>
                  Laporan Potensi Masyarakat
                </p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/banner"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-image"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                <p style={{ fontSize: "12px", color: "black" }}>Banner</p>
              </div>
            </Link>
          </div>
          <div className="card col-sm-1 p-0 mr-2 mb-0">
            <Link to={process.env.PUBLIC_URL + "/admin/perangkat"}>
              <div className="card-body p-1 text-center text-middle">
                <i
                  className="fa fa-building"
                  style={{ fontSize: "60px", color: "#2e99b0" }}
                />
                {/* <p style={{ fontSize: '12px', color: 'black' }}>Perangkat {JENIS_DESA}</p> */}
                <p style={{ fontSize: "12px", color: "black" }}>Perangkat </p>
              </div>
            </Link>
          </div>
          {/* <div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/data-master-potensi'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-bar-chart-o" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Data Master Potensi Desa</p>
							</div>
						</Link>
					</div> */}
          {/* <div className="card col-sm-1 p-0 mr-2 mb-0">
						<Link to={process.env.PUBLIC_URL + '/admin/wisata'}>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-image" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Wisata</p>
							</div>
						</Link>
					</div> */}

          {/* <div className="card col-sm-1 p-0 mr-2 mb-0">
						<a
							href="javascript:void"
							onClick={() => {
								this.bukaMenu('wilayah');
							}}
						>
							<div className="card-body p-1 text-center text-middle">
								<i className="fa fa-globe" style={{ fontSize: '60px', color: '#2e99b0' }} />
								<p style={{ fontSize: '12px', color: 'black' }}>Wilayah</p>
							</div>
						</a>
					</div> */}
        </div>
      );
    };

		let title = '';
		if (this.props.location.pathname == '/admin/dashboard') {
			title = 'Dashboard';
		} else if (this.props.location.pathname == '/admin/role') {
			title = 'Role';
		} else if (this.props.location.pathname == '/admin/pengguna') {
			title = 'Pengguna';
		} else if (this.props.location.pathname == '/admin/bumdes') {
			title = 'Bumdes';
		} else if (this.props.location.pathname == '/admin/topup') {
			title = 'Top Up Dana';
		} else if (this.props.location.pathname == '/admin/banner') {
			title = 'Konfigurasi Banner';
		} else if (this.props.location.pathname == '/admin/transaksi') {
			title = 'Transaksi';
		} else if (this.props.location.pathname == '/admin/pengguna') {
			title = 'Pengguna';
		} else if (this.props.location.pathname == '/admin/agen') {
			title = 'Agen/Mitra';
		} else if (this.props.location.pathname == '/admin/prepaid') {
			title = 'Konfigurasi Profit Perusahaan Prepaid';
		} else if (this.props.location.pathname == '/admin/postpaid') {
			title = 'Konfigurasi Profit Perusahaan Postpaid';
		} else if (this.props.location.pathname == '/admin/campaign') {
			title = 'Konfigurasi Campaign';
		} else if (this.props.location.pathname == '/admin/rekap-profit') {
			title = 'Rekap Profit Perusahaan';
		} else if (this.props.location.pathname == '/admin/pengembalian-dana') {
			title = 'Pengembalian Dana';
		} else if (this.props.location.pathname == '/admin/pencairan-dana') {
			title = 'Pencairan Dana';
		} 

    // const dataUser = jwt.decode(sessionStorage.getItem('access_token'));
    // const jenisUser = dataUser.sub.jenis;

    return (
      <div className="page-wrapper box-layout">
        <Header />
        <div className="page-body-wrapper ">
          <Sidebar />
          {/* <Sidebar /> */}
          <div className="page-body" style={{ padding: 0, paddingBottom: "85px" }}>
            <div className="container-fluid" style={{ padding: "0 35px" }}>
              <div className="page-header">
                <div className="row">
                  <div className="col-lg-6">
                    <h3>
                      <small>Aplikasi Manajemen PPOB</small>
                      {title}
                    </h3>
                  </div>
                  <div className="col-lg-6">
                    {/* <ol className="breadcrumb pull-right">
											<li className="breadcrumb-item">
												<Link to="/admin/dashboard">
													<i className="fa fa-home" />
												</Link>
											</li>
											<li className="breadcrumb-item">Dashboard</li>
											{title != 'Dashboard' ? (
												<li className="breadcrumb-item active">{title}</li>
											) : (
													<div />
												)}
										</ol> */}
                  </div>
                </div>
              </div>
            </div>

            {/* Isi component dari Component ini (App) */}
            <div style={{ padding: "0 20px" }}>
              {this.props.children}
            </div>

            {/* <div className="footer"> */}
              {/* <Footer /> */}
              <div className="container-fluid" style={{ bottom: 0, position: "absolute", backgroundColor: "#dedede", boxShadow: "" }}>
                <div className="page-header" style={{ padding: 0 }}>
                  <div className="row" style={{ padding: "10px 0"}}>
                    <div className="col-lg-12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <h5>
                        Aplikasi Manajemen PPOB
                      </h5>
                      <h5>Digidesa</h5>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
            
          </div>
          {/* <Customizer /> */}
        </div>
        
      </div>
    );
  }
}

export default App;
