// import { JENIS_DESA, JENIS_DUSUN } from '../function/Koneksi';

export const MENUITEMS = [
	{
		title: 'Dashboard',
		icon: 'desktop',
		type: 'link',
		path: '/admin/dashboard',
		badgeType: 'primary',
		active: true,
		// children: [
		// 	{ path: '/dashboard/default', title: 'Default', type: 'link' },
		// 	{ path: '/dashboard/ecommerce', title: 'E-Commerce', type: 'link' }
		// ]
	},
	{
		title: 'Data Master',
		icon: 'blackboard',
		type: 'sub',
		active: false,
		children: [
			{ path: '/admin/bank', title: 'Bank', type: 'link' },
			{ path: '/admin/rekening', title: 'Nomor Rekening Perusahaan', type: 'link' },
		]
	},
	{
		path: '/admin/bumdes',
		title: 'BUMDES',
		icon: 'home',
		type: 'link'
	},
	{
		path: '/admin/agen',
		title: 'Agen/Mitra',
		icon: 'truck',
		type: 'link'
	},
	{
		path: '/admin/topup',
		title: 'Top Up Dana',
		icon: 'credit-card',
		type: 'link'
	},
	{
		path: '/admin/pencairan-dana',
		title: 'Pencairan Dana',
		icon: 'credit-card',
		type: 'link'
	},
	{
		path: '/admin/pengguna',
		title: 'Pengguna',
		icon: 'user',
		type: 'link'
	},
	
	// {
	// 	path: '/admin/pengguna',
	// 	title: 'Pengguna',
	// 	icon: 'settings',
	// 	type: 'link'
	// },
	{
		path: '/admin/transaksi',
		title: 'Transaksi',
		icon: 'shopping-cart-full',
		type: 'link'
	},
	{
		path: '/admin/rekap-profit',
		title: 'Rekap Profit',
		icon: 'shopping-cart-full',
		type: 'link'
	},
	{
		path: '/admin/pengembalian-dana',
		title: 'Pengembalian Dana',
		icon: 'shopping-cart-full',
		type: 'link'
	},
	// {
	// 	path: '/admin/konfig-global',
	// 	title: 'Konfigurasi',
	// 	icon: 'settings',
	// 	type: 'link'
	// },
	{
		title: 'Konfigurasi',
		icon: 'settings',
		type: 'sub',
		active: false,
		children: [
			{ path: '/admin/banner', title: 'Banner', type: 'link' },
			{ path: '/admin/campaign', title: 'Campaign', type: 'link' },
			{ path: '/admin/prepaid', title: 'Profit Perusahaan Prepaid', type: 'link' },
			{ path: '/admin/postpaid', title: 'Profit Perusahaan Postpaid', type: 'link' },
		]
	},
];
