import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Datepicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';

import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Switch from "react-switch";

// koneksi
import { PostStatus, Post, Get, Delete, cekLogin, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from 'reactdatepicker';
import classnames from 'classnames';

//json file
// var data = require('../assets/json/company');
const frmDef = {
	uuid: null,
	uuid_bank: null,
	nomor_rekening: '',
	atas_nama: '',
	status: 'Aktif',
	jenis: null,
};

class Rekening extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dataTidakAktif: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				statGet: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			statUbah: false,
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			active_tab_icon: '1',
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kelStat: null,
			pendStat: [],
			frmPen: [],
			jpStat: [],
			frmJp: [],
			formPenduduk: 'none',
			dataVerifikasi: [],
			photoIndex: 0,
			isOpen: false,
			idVerifikasi: null,
			dataReset: {
				uuid: '',
				username: ''
			},
			jenisUser: [],
			id_jenisUser: null,
			tingkatUser: [],
			id_tingkatUser: null,

			loading: true,
			cariKata: '',
			listBank: [],
		};
	}

	// ================== DatePicker ==================
	gantiTglMulai = (date) => {
		this.state.dt.tanggal_mulai = date;
		this.forceUpdate();
	};
	gantiTglSelesai = (date) => {
		this.state.dt.tanggal_selesai = date;
		this.forceUpdate();
	};
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '&page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '&page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '&page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '&page=1';
					break;
				default:
					link = '&page=' + params.page;
					break;
			}
		}
		let status = 'ya'
		console.log(this.state.active_tab_icon)
		console.log(params.tab)

		switch (params.tab) {
			case '1':
				status = 'ya'
				break;
			case '2':
				status = 'tidak'
				break;
		
			default:
				break;
		}

		Get('nomor-rekening-perusahaan?jenis_perusahaan=' + status + '&keyword=' + this.state.cariKata + link, null, (dtkat) => {
			console.log(dtkat);
			if (dtkat.data && dtkat.data.data) {
				switch (params.tab) {
					case '1':
						this.setState({ 
							data: dtkat.data.data, 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case '2':
						this.setState({ 
							dataTidakAktif: dtkat.data.data, 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					default:
						this.setState({ 
							data: dtkat.data.data, 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				}
				
				this.pagination(dtkat.data, params.tab);
			} else {
				switch (params.tab) {
					case 'ya':
						this.setState({ 
							data: [], 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					case 'tidak':
						this.setState({ 
							dataTidakAktif: [], 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				
					default:
						this.setState({ 
							data: [], 
							loading: false, 
							pagination: dtkat.data 
						});
						break;
				}
			}
		});
	};

	// Pagination
	pagination = (data, tab) => {
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total_page > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.total_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.total_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.total_page - 4;
				}
				end = data.total_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i, tab })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n, tab })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.total_page, tab })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
		console.log(awal);
		console.log(sebelum);
		console.log(hal);
		console.log(setelah);
		console.log(akhir);
	};
	// Cari Data Table
	cariData = (e, tab) => {
		if (e.key == 'Enter') {
			this.state.loading = true;
			this.state.cariKata = e.target.value;
			this.forceUpdate();
			let addRole = {
				q: e.target.value
			};

			let status = 'ya'

			switch (tab) {
				case '1':
					status = 'ya'
					break;
				case '2':
					status = 'tidak'
					break;
			
				default:
					break;
			}

			Get('nomor-rekening-perusahaan?keyword=' + e.target.value + '&jenis_perusahaan=' + status, null, (dtkat) => {
				console.log(dtkat)
				if (dtkat.data.data) {
					switch (tab) {
						case '1':
							this.setState({
								data: dtkat.data.data,
								loading: false,
								pagination: dtkat.data
							});
							break;
						case '2':
							this.setState({
								dataTidakAktif: dtkat.data.data,
								loading: false,
								pagination: dtkat.data
							});
							break;
					
						default:
							break;
					}
				} else {
					switch (tab) {
						case '1':
							this.setState({
								data: [],
								loading: false,
								pagination: dtkat.data
							});
							break;
						case '2':
							this.setState({
								dataTidakAktif: [],
								loading: false,
								pagination: dtkat.data
							});
							break;
					
						default:
							break;
					}
				}
				this.pagination(dtkat.data, tab);
			});
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		if (this.state.dt.uuid_bank && this.state.dt.jenis) {
			// Baru sampai sini
			let bodyRaw = {
				uuid: document.getElementById('uuid').value,
				// uuid_bank: document.getElementById('uuid_bank').value,
				uuid_bank: this.state.dt.uuid_bank.value,
				nomor_rekening: document.getElementById('nomor_rekening').value,
				atas_nama: document.getElementById('atas_nama').value,
				status: document.getElementById('status').value,
				// jenis: document.getElementById('jenis').value,
				jenis: this.state.dt.jenis.value,
			};

			console.log(bodyRaw);

			let psn = 'Tambah';
			let resstat = 200;
			let metode = 'create';

			if (bodyRaw.uuid != '') {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
			} else {
				bodyRaw.uuid = null;
				// bodyRaw.password = document.getElementById('password').value;
			}

			Post('nomor-rekening-perusahaan', bodyRaw.uuid, bodyRaw, (res) => {
				console.log(res);
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Nomor Rekening',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.componentDidMount(this.state.active_tab_icon);
					this.tutupForm();
				} else {
					let message = 'Gagal ' + psn + ' Data'

					if (res.data.data.Message && res.data.data.Message.toLowerCase().indexOf('duplicate') > -1) {
						message = "Username telah digunakan"
					}

					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Nomor Rekening',
						// pesanAlert: 'Gagal ' + psn + ' Data'
						// pesanAlert: res.data.data.Message
						pesanAlert: message
					});
				}
			});
		} else {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Rekening',
				pesanAlert: 'Isi seluruh data dengan lengkap'
			});
		}
	};
	// ========================================================================

	// =
	// ============================== Ubah Data ===============================

	ubahData = (data) => {
		console.log(data);
		// this.state.dt.id_wilayah = data.id_wilayah;
		// this.state.dt.jenis = data.jenis;
		// this.state.dt.nip = data.nip;
		// this.state.dt.status = data.status;
		// this.state.dt.tingkat = data.tingkat;

		this.state.dt.uuid = data.uuid;
		this.state.dt.uuid_bank = {
			value: data.uuid_bank,
			label: data.Bank.akronim + ' - ' + data.Bank.nama
		}
		this.state.dt.nomor_rekening = data.nomor_rekening;
		this.state.dt.atas_nama = data.atas_nama;
		this.state.dt.status = data.status;
		this.state.dt.jenis = {
			value: data.jenis,
			label: data.jenis
		};

		this.state.status.btnAksi = false;
		this.forceUpdate();
		this.bukaForm();
		// });
	};

	konfirmUpdateStatus = (uuid, status) => {
		let statusText = status === 'aktif' ? 'menonaktifkan' : 'mengaktifkan'
		
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Iya"
					cancelBtnText="Batal"
					confirmBtnBsStyle="warning"
					cancelBtnBsStyle="success"
					type="warning"
					title={"Apakah anda yakin ingin " + statusText + " rekening ini ?"}
					onCancel={this.hideAlert}
					onConfirm={() => this.updateStatus(uuid, status)}
				>
					{/* Proses ini tidak dapat dibatalkan! */}
				</SweetAlert>
			)
		});
	};

	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Apakah anda yakin ingin hapus data ini ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';

		let bodyRaw = {
			uuid: id
		};

		Delete('nomor-rekening-perusahaan', bodyRaw, (res) => {
			console.log(res);
			if (res == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Rekening',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
				this.componentDidMount(this.state.active_tab_icon);
				this.setState({ status: { ...this.state.status, form: false } });
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Rekening',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	updateStatus = (uuid, status) => {
		let bodyRaw = {
			uuid : uuid, 
			status : status === 'aktif' ? 'Tidak Aktif' : 'Aktif'
		}

		Post('nomor-rekening-perusahaan', bodyRaw.uuid, bodyRaw, (res) => {
			console.log(res);
			if (res.status === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Rekening',
					pesanAlert: 'Berhasil ubah status rekening',
					alert: null
				});
				this.componentDidMount(this.state.active_tab_icon);
				this.tutupForm();
			} else {
				let message = 'Gagal ubah status rekening'

				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Rekening',
					pesanAlert: message,
					alert: null
				});
			}
		});
	}

	tutupForm = () => {
		this.state.dt.uuid = null;
		this.state.dt.username = '';
		this.state.dt.password = '';
		this.state.dt.alamat = '';
		this.state.prvStat = null;
		this.state.kbktStat = null;
		this.state.kecStat = null;
		this.state.kldsStat = null;
		this.state.kelStat = null;
		this.state.id_tingkatUser = null;
		this.state.id_jenisUser = null;
		this.state.cariKata = '';

		this.state.status.form = false;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		console.log(row);
		let status = row.status.toLowerCase()

		return (
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => {
						this.setState({
							judul: 'Ubah Perangkat',
							statUbah: false
						});
						this.ubahData(row);
					}}
					title="Edit"
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>
				{' '}
				<Button
					size="xs"
					color={status === 'aktif' ? 'danger' : 'info'}
					className="btn-icon"
					onClick={() => this.konfirmUpdateStatus(row.uuid, status)}
					disabled={this.state.status.btnAksi}
					style={{ marginLeft: '5px' }}
					title={row.status.toLowerCase() === 'aktif' ? 'Non-aktifkan rekening' : 'Aktifkan rekening'}
				>
					<i className="fa fa-retweet" />
				</Button>
				{' '}
				<Button
					size="xs"
					color={'danger'}
					className="btn-icon"
					onClick={() => this.konfirmHapus(row.uuid)}
					disabled={this.state.status.btnAksi}
					style={{ marginLeft: '5px' }}
					title="Hapus"
				>
					<i className="fa fa-trash" />
				</Button>
			</div>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		let formDusun = this.state.frmDus;

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						if (this.state.id_kategori_penduduk.value == 1) {
							this.setState({ frmDus: frm });
						} else {
							this.setState({ frmDus: formDusun });
						}
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};
	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jenis':
					this.setState({ dt : {...this.state.dt, jenis: e} })
					break;
				case 'bank':
					this.setState({ dt : {...this.state.dt, uuid_bank: e} })
					break;
				case 'tingkatUser':
					this.setState({
						id_tingkatUser: e,
						prvStat: null,
						kbktStat: null,
						kecStat: null
					});
					break;
			}
		} else {
			switch (sel) {
				case 'jenis':
					this.setState({ dt : {...this.state.dt, jenis: null} })
					break;
				case 'bank':
					this.setState({ dt : {...this.state.dt, uuid_bank: null} })
					break;
			}
		}
		this.state.status.select = false;
		this.forceUpdate();
	};

	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			switch (tab) {
				case '1':
					this.fetch({ page: null, where: [], tab: '1' })
					break;
				case '2':
					this.fetch({ page: null, where: [], tab: '2' })
					break;
			}

			this.setState({
				active_tab_icon: tab
			});
		}
	}

	componentDidMount(tab = '1') {
		this.fetch({ page: null, where: [], tab });

		Get('bank', null, (dtkat) => {
			console.log(dtkat);
			if (dtkat.data.data) {
				let listBank = []

				dtkat.data.data.map((e, index) => {
					listBank.push({ value: e.uuid, label: e.akronim + ' - ' + e.nama })
				})

				this.setState({listBank});
			} else {
				this.setState({ 
					listBank: [], 
				});
			}
		});
	}

	render() {
		var data = this.state.data;

		switch (this.state.active_tab_icon) {
			case '1':
				data = this.state.data;
				break;

			case '2':
				data = this.state.dataTidakAktif;
				break;
		}

		// console.log(data);

		// var data = require('../assets/json/company');

		// const frmDef = {
		// 	uuid: null,
		// 	username: '',
		// 	password: '',
		// 	nik: '',
		// 	id_role: '',
		// 	status: null,
		// 	no_hp: null,
		// 	email: null
		// };
		const { SearchBar } = Search;
		const columns = [
			{
				dataField: 'atas_nama',
				text: 'Atas Nama',
				sort: true
			},
			{
				dataField: 'Bank.akronim',
				text: 'Nama Bank',
				sort: true
			},
			{
				dataField: 'nomor_rekening',
				text: 'Nomor Rekening',
				sort: true
			},
			{
				dataField: 'jenis',
				text: 'Jenis',
				sort: true
			},
			{
				dataField: 'status',
				text: 'Status',
				sort: true
			},
			// {
			//   dataField: "tingkat",
			//   text: "Tingkat",
			//   sort: true
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		let bootstrapTable = (
			<div>
				<BootstrapTable keyField="uuid" data={data} columns={columns} />
				<div className="pull-right text-white">
					{this.state.awal}
					{this.state.sebelum}
					{this.state.hal.map((dt) => {
						return dt;
					})}
					{this.state.setelah}
					{this.state.akhir}
				</div>
			</div>
		)

		let jenisRekening = [
			{
				value: 'SDM',
				label: 'SDM',
			}
			
		]
		if (this.state.active_tab_icon == '2') {
			jenisRekening = [
				{
					value: 'BUMDES',
					label: 'BUMDES',
				},
				{
					value: 'Mitra',
					label: 'Mitra',
				}
			]
		}

		return (
			<div>
				{/* <Breadcrumb title="Penguna" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-body datatable-react">
									<Nav tabs className="tabs-color">
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '1'
												})}
												onClick={() => {
													this.toggle_icon('1');
												}}
											>
												Perusahaan
											</NavLink>
										</NavItem>
										<NavItem style={{ cursor: 'pointer' }}>
											<NavLink
												className={classnames({
													active: this.state.active_tab_icon === '2'
												})}
												onClick={() => {
													this.toggle_icon('2');
												}}
											>
												BUMDES & Agen
											</NavLink>
										</NavItem>
									</Nav>
									<div className="row" style={{ flexDirection: 'row-reverse', marginTop: 15 }}>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												style={{ marginBottom: '15px' }}
												onClick={() => {
													this.setState({
														judul: 'Tambah Nomor Rekening',
														// dt: frmDef,
														dt: {
															uuid: null,
															uuid_bank: null,
															nomor_rekening: '',
															atas_nama: '',
															status: 'Aktif',
															jenis: null,
														},
													});
													this.state.dt.uuid = null;
													this.state.id_tingkatUser = null;
													this.state.id_jenisUser = null;
													this.forceUpdate();
													this.bukaForm();
												}}
											>
												Tambah Nomor Rekening
											</Button>
										</div>
										<div className="col-sm-6">
											<input
												type="text"
												className="form-control"
												id="cariTable"
												placeholder="Cari Rekening"
												onKeyPress={(e) => this.cariData(e, this.state.active_tab_icon)}
												style={{ marginBottom: '15px' }}
											/>
										</div>
									</div>

									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="1">
											<div style={{ marginTop: 15 }}>
												{this.state.loading ? (
												<div align="center">
													<img
														src={require('../assets/images/loading-bos.gif')}
														style={{
															width: '100%',
															borderRadius: '10px',
															width: '70px'
														}}
													/>
												</div>
											) : (
												<div className="row">
													<div className="col-sm-12">
														{this.state.active_tab_icon == '1' ? bootstrapTable : ''}
													</div>
												</div>
											)}
											</div>
										</TabPane>
										<TabPane tabId="2">
											<div style={{ marginTop: 15 }}>
												{this.state.loading ? (
												<div align="center">
													<img
														src={require('../assets/images/loading-bos.gif')}
														style={{
															width: '100%',
															borderRadius: '10px',
															width: '70px'
														}}
													/>
												</div>
											) : (
												<div className="row">
													<div className="col-sm-12">
														{this.state.active_tab_icon == '2' ? bootstrapTable : ''}
													</div>
												</div>
											)}
											</div>
										</TabPane>
									</TabContent>

									
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />

				<Modal
					size="lg"
					open={this.state.status.form}
					onClose={this.tutupForm}
					styles={{ modal: { width: '80%' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div size="lg" className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
							/>
							<input
								className="form-control"
								id="status"
								type="hidden"
								defaultValue={this.state.dt.status}
							/>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Bank
								</Label>
								<Col sm="9">
									<Select
										// isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={e => {
											this.changeSelectValue(e, "bank");
										}}
										// defaultValue={this.state.stpStat}
										value={this.state.dt.uuid_bank ? this.state.dt.uuid_bank : null}
										name="jenis"
										options={this.state.listBank}
										placeholder={'Pilih Jenis Bank'}
										isClearable
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nomor Rekening
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nomor_rekening"
										type="number"
										placeholder="Masukkan Nomor Rekening"
										required
										defaultValue={this.state.dt.nomor_rekening}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Atas Nama
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="atas_nama"
										type="text"
										placeholder="Masukkan Nama Pemilik Rekening"
										required
										defaultValue={this.state.dt.atas_nama}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Jenis
								</Label>
								<Col sm="9">
									<Select
										// isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={e => {
											this.changeSelectValue(e, "jenis");
										}}
										// defaultValue={this.state.stpStat}
										value={this.state.dt.jenis ? this.state.dt.jenis : null}
										name="jenis"
										options={ jenisRekening }
										placeholder={'Pilih Jenis Rekening'}
										isClearable
									/>
								</Col>
							</FormGroup>

						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								// disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								// disabled={this.state.status.btnForm}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Rekening;
